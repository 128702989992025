import './App.scss';
import { Home } from './components/home/Home';
import { BrowserRouter as Router, Routes, Route, HashRouter } from "react-router-dom";
import { Login } from './components/login/Login';

function App() {

  return (
    <HashRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
