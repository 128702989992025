import './Home.scss';
import 'react-splitter-layout/lib/index.css';
import axios from 'axios';
import PanoramaContainer from '../containers/panorama-container/PanoramaContainer';
import { useContext, useEffect, useRef, useState } from 'react';
import { Navbar } from '../layouts/navbar/Navbar';
import { useNavigate } from "react-router-dom";
import { useAppSelector } from '../../state/hooks';
import { useDispatch } from 'react-redux';
import { setLoginStatus, setUserDetails } from '../../state/features/loginSlice';
import { MapContainer } from '../containers/map-container/MapContainer';
import { setIsMultiOpen } from '../../state/features/panoramaSlice';
import { Basemap } from '../basemap/Basemap';
import { Legend } from '../legend/Legend';
import { Toast } from 'primereact/toast';
import { Information } from '../shared/information/Information';
import { setAllFields, setDomainFields } from '../../state/features/domainSlice';
import { setIsLayerVisible, setProjects, setServices } from '../../state/features/layerSlice';
import { Sidebar } from '../layouts/sidebar/Sidebar';
import { Table } from '../shared/table/Table';
import { ServiceRestApi } from '../../util/restapi/services';
import { DigiCallbackContextProvider } from '../containers/shared/digitiziation/DigiCallbackContextProvider';
import { ProjectRestApi } from '../../util/restapi/project';
import { addLocale, locale } from 'primereact/api';
import { Help } from '../help/Help';
import { Import } from '../import/Import';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';
import { Print } from '../print/Print';
import { LoginRestApi } from '../../util/restapi/login';
import { Export } from '../export/Export';
import { Footer } from '../layouts/footer/Footer';
import { ScreenShot } from '../screenshot/ScreenShot';
import Splitter from "m-react-splitters";
import { setToken } from '../../state/features/customizeSlice';

export const Home = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useRef<any>();
    const [showTable, setShowTable] = useState<boolean>(false)
    const [selectedPrint, setSelectedPrint] = useState<any>(null)
    const loginStatus = useAppSelector(state => state.login.loginStatus);
    const toastVisibility = useAppSelector(state => state.login.showToast);
    const isFullScreen = useAppSelector(state => state.panorama.isFullScreen);
    const projects: any = useAppSelector(state => state.layer.projects);
    const legendVisibility: any = useAppSelector(state => state.map.legendVisibility);
    const tableVisibility: any = useAppSelector(state => state.buttonState.tableButton);
    const sidebarVisibility: any = useAppSelector(state => state.buttonState.sidebarButton);
    const screenshotVisibility = useAppSelector(state => state.buttonState.screenshotButton)
    const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature);
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)
    const language = useAppSelector(state => state.language.value);
    const buttonState = useAppSelector(state => state.buttonState.buttonState);
    const importCompleted = useAppSelector((state) => state.import.importCompleted)
    const displayPrint = useAppSelector((state) => state.buttonState.printButton);
    const displayExport = useAppSelector((state) => state.buttonState.exportButton);
    const panoramaType = useAppSelector(state => state.panorama.type);
    const isPanoMiniScreen = useAppSelector(state => state.panorama.isPanoMiniScreen);
    const mapCoordinate = useAppSelector((state: any) => state.url.mapCoord)
    const angle = useAppSelector((state) => state.panorama.angle)
    const panoramaCoordinate: any = useAppSelector(state => state.panorama.panoramaCoordinate)
    const urlView: any = useAppSelector(state => state.url.urlView)
    const token = useAppSelector((state: any) => state.customize.token);

    useEffect(() => {
        if (token) {
            getLoginUser()
        }
    }, [token])

    useEffect(() => {
        if (panoramaCoordinate && mapCoordinate[0] && angle) {

            const storage: any = localStorage.getItem('view')?.split(',')
            if (storage && storage.length > 0) {
                navigate(`@Pan${panoramaCoordinate.lon + ',' + panoramaCoordinate.lat}@Map${mapCoordinate[0] + ',' + mapCoordinate[1]}@View${storage[0]},${storage[1]},${storage[2]}`)
            }
            else {
                navigate(`@Pan${panoramaCoordinate.lon + ',' + panoramaCoordinate.lat}@Map${mapCoordinate[0] + ',' + mapCoordinate[1]}@View${1.000},${0.0},${0.0}`)
            }
            localStorage.setItem('lastCoordinate', panoramaCoordinate.lon + ',' + panoramaCoordinate.lat)


        }
    }, [panoramaCoordinate, angle, mapCoordinate, urlView])

    const getLoginUser = async () => {
        const res = await LoginRestApi.loginUser()
        if (res && res.data.success) {
            dispatch(setUserDetails(res.data.user))
        } else {
            localStorage.removeItem('token');
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
            dispatch(setToken(undefined));
        }
    }

    useEffect(() => {
        if (panoramaType === 'Multi Display') {
            dispatch(setIsMultiOpen(true))
        } else {
            dispatch(setIsMultiOpen(false))
        }
    }, [panoramaType])

    useEffect(() => {
        axios.interceptors.response.use(
            (successfulReq) => {
                return successfulReq;
            },
            (error) => {
                if (error.response.status === 401 /*|| error.response.status === 400*/) {
                    dispatch(setLoginStatus('LOGOUT'));
                    localStorage.removeItem('token');
                }
                if (error.response.message) {
                    toast.current?.show({ severity: 'error', summary: 'Error Message', detail: error.response.message, life: 3000 });
                }
                if (!error.response.message) {
                    toast.current?.show({ severity: 'error', summary: 'Error Message', detail: error.message, life: 3000 });
                }
                return Promise.reject(error);
            }
        );
    }, [])

    useEffect(() => {
        if (token === null) {
            dispatch(setLoginStatus('LOGOUT'))
        }
        if (token) {
            dispatch(setLoginStatus('LOGIN'))
            getProjects();
            //      localStorage.setItem('sidebarLayersAreOpened', JSON.stringify([]));
            // getServices();
        }
        document.addEventListener('contextmenu', rightClickHandler)
        return () => document.removeEventListener('contextmenu', rightClickHandler)
    }, [])

    useEffect(() => {
        if (language === 'tr') {
            addLocale('tr', {
                startsWith: ['İle Başlayan'],
                contains: ['İçerir'],
                notContains: ['İçermez'],
                endsWith: ['İle Biten'],
                equals: ['Eşittir'],
                notEquals: ['Eşit Değildir'],
                lt: ['Küçüktür'],
                lte: ['Küçük Eşittir'],
                gt: ['Büyüktür'],
                gte: ['Büyük eşittir'],
                between: ['Arasında'],
                dateIs: ['Tarih eşittir'],
                dateIsNot: ['Tarih eşit değildir'],
                dateBefore: ['Tarihten öncesi'],
                dateAfter: ['Tarihten sonrası'],
                matchAll: ['Hepsini Eşleştir'],
                matchAny: ['Herhangi Biriyle Eşleştir'],
                addRule: ['Kural Ekle'],
                clear: ['Temizle'],
                apply: ['Uygula'],
                emptyMessage: ['Sonuç Bulunamadı.'],
            });
            locale('tr');
        } else {
            addLocale('eng', {
                startsWith: ['Starts with'],
                contains: ['Contains'],
                notContains: ['Not contains'],
                endsWith: ['Ends with'],
                equals: ['Equals'],
                notEquals: ['Not equals'],
                lt: ['Less than'],
                lte: ['Less than or equal to'],
                gt: ['Greater than'],
                gte: ['Greater than or equal to'],
                between: ['Between'],
                dateIs: ['Date is'],
                dateIsNot: ['Date is not'],
                dateBefore: ['Date before'],
                dateAfter: ['Date after'],
                matchAll: ['Match all'],
                matchAny: ['Match any'],
                addRule: ['Add Rule'],
                clear: ['Clear'],
                apply: ['Apply'],
                emptyMessage: ['No results found'],
            });
            locale('eng');
        }
    }, [language])

    //S.Soytürk yoruma aldı. Tabloda "gotoselection" yaparken burası hata veriyor.
    // useEffect(() => {
    //     if (selectedFeature.id) {
    //         const selectedLayerName = selectedFeature.id.split('.')[0];
    //         projects.map((layerGroup: any) => {
    //             const selectedLayer = layerGroup.layers.find((layer: any) => layer.name === selectedLayerName);
    //             if (selectedLayer) {
    //                 dispatch(setSelectedLayerInfo(selectedLayer))
    //                 const url = services.find((service: any) => service.id === selectedLayer.service_id)?.url
    //                 FeatureRestApi.getFeatures(url, selectedLayer.name).then((res: any) => {
    //                     const copyFeatures = Object.assign([], res.data.features)
    //                     dispatch(setFeatures(copyFeatures));
    //                 })
    //             }
    //         })
    //     }

    // }, [selectedFeature, transactStatus])

    useEffect(() => {
        if (selectedLayer) {
            getServices();
            setShowTable(selectedLayer)
        }
    }, [selectedLayer])
    const selectPrint = (val: any) => {
        setSelectedPrint(val)
    }
    const rightClickHandler = (event: any) => {
        event.preventDefault();
    }

    useEffect(() => {
        if (loginStatus === 'LOGOUT') {
            navigate("login");
            localStorage.removeItem('token')
        }
        return () => {
            dispatch(setButtonState(ButtonState.NONE))
        }
    }, [loginStatus])

    useEffect(() => {
        if (toastVisibility) {
            toast.current?.show({ severity: 'success', detail: ('Giriş başarılı'), life: 3000 });
        }
    }, [toastVisibility])

    useEffect(() => {
        if (importCompleted) {
            toast.current?.show({ severity: 'success', detail: ('Katman içe aktarıldı!'), life: 3000 });
        }
    }, [importCompleted])

    useEffect(() => {
        if (projects && projects.length > 0) {
            const beyogluLayerGroup: any = projects.find((layerGroup: any) => layerGroup.name === 'beyoglu');
            const yolLayer: any = beyogluLayerGroup?.layers.find((layer: any) => layer.name === 'beyoglu_yol_usr');
            const fields: any = yolLayer?.fields;

            dispatch(setAllFields(fields))

            //domainleri bulup layerlarına göre gruplandırdık.
            let domainArray: any[] = [];
            let allDomainFields: any[] = [];

            projects.map((project: any) => {
                if (project.layer_groups && project.layer_groups.length > 0) {
                    project.layer_groups.map((layerGroup: any) => {
                        domainArray = [];
                        layerGroup.layers.map((layer: any) => {
                            layer.fields.map((field: any) => {
                                if (field.type === 'Domain') {
                                    domainArray.push(field)
                                }
                            })
                        })
                        const domainObject = {
                            layerId: layerGroup.id,
                            domains: domainArray
                        }
                        allDomainFields.push(domainObject)
                    })
                }
            })
            dispatch(setDomainFields(allDomainFields))
        }
    }, [projects])

    // useEffect(() => {
    //     const layerArr: any[] = []
    //     if (projects && projects.length > 0) {
    //         const newArray = projects.map((project: Project) => {
    //             const layerGroups = project.layer_groups.map((layerGroup: LayerGroup) => {
    //                 const layers = layerGroup.layers.find((layer: any) => {
    //                     if (layer.visible) {
    //                         console.log(layer);
    //                         const layerObj = {
    //                             name: layer.name,
    //                             visible: layer.visible
    //                         }
    //                         layerArr.push(layerObj)
    //                     }
    //                 })
    //             })
    //         })
    //         localStorage.setItem('sidebarLayersAreOpened', JSON.stringify(layerArr))
    //     }
    // }, [projects])

    // const getLayers = () => {
    //     LayerRestApi.getLayerGroups().then((response) => {
    //         const success = response.data.success
    //         if (success) {
    //             const layerGroups = response.data.layerGroups.filter((layerGroup: any) =>
    //                 layerGroup.name !== 'default_group'

    //             );

    //             // const immovables = layerGroups.find((layer: any) => { return layer.name === 'beyoglu' })
    //             // if (immovables) {
    //             //     immovables.layers.find((obj: any) => {
    //             //         if (obj.name === 'beyoglu_yol_usr') {
    //             //             obj.visible = true;
    //             //         }
    //             //     })
    //             // }
    //             dispatch(setLegendVisibility(true));
    //             dispatch(setLayerGroups(layerGroups));
    //         }
    //     });
    // }

    const getProjects = async () => {
        try {
            const response = await ProjectRestApi.getProjects();
            dispatch(setProjects(response.data.projects));

        } catch (error) {
            toast.current?.show({ severity: 'error', detail: ('Bir hata oluştu'), life: 3000 });
        }
    }

    const getServices = () => {
        ServiceRestApi.getServices().then((response: any) => {
            const success = response.data.success
            if (success) {
                const services = response.data.services.services
                dispatch(setServices(services));
            }
        })
    }
    const toastMessage = (message: any) => {
        toast.current?.show({ severity: 'warn', summary: ('Uyarı'), detail: (message), life: 5000 });
    }

    return (
        <>
            <DigiCallbackContextProvider>
                <div className='home'>
                    <div style={{ visibility: screenshotVisibility ? 'visible' : 'hidden' }}>
                        <ScreenShot />
                    </div>
                    <div className='navbar non-printable'>
                        <Navbar showMessage={toastMessage} />
                        <div className={sidebarVisibility ? 'show' : 'hide'}>
                            <Sidebar />
                        </div>
                        <div className={!sidebarVisibility && selectedLayer.id ? 'show' : 'hide'}>
                            <div className='selected-layer'>{selectedLayer.name}</div>
                        </div>
                    </div>
                    {legendVisibility && <div className='legend-container'>
                        <Legend />
                    </div>}
                    {buttonState === ButtonState.INFO && selectedFeature && selectedFeature.length === 1 && selectedFeature[0].type && <Information />}
                    <div className={`containers ${tableVisibility ? 'containers-table-opened' : ''}`}>
                        <Splitter
                            position="vertical"
                            primaryPaneMinWidth='0%' //'10%'
                            primaryPaneMaxWidth='100%'
                            primaryPaneWidth={selectedPrint === 'only-map' ? "100%" : selectedPrint === 'both' || !selectedPrint ? "50%" : "0%"}
                            dispatchResize={true}
                            postPoned={false}
                            className={`test2 ${isFullScreen ? 'test1' : ''}`}
                            primaryPaneClassName={`${isFullScreen && !isPanoMiniScreen ? 'is-map-mini-screen' : ''}`}
                            secondaryPaneClassName={`${isFullScreen && isPanoMiniScreen ? 'is-pano-mini-screen' : 'x'}`}
                        >
                            <MapContainer showMessage={toastMessage} />

                        </Splitter>
                    </div>
                    <Footer />
                    {/* <div className={`table ${tableVisibility ? 'table-opened' : ''}`} style={{ height: `${height_}px` }}> */}
                    <div className={`table ${tableVisibility ? 'table-opened' : ''}`} >
                        {tableVisibility && showTable && <Table />}
                    </div>
                    {!tableVisibility && <div className='basemap'>
                        <Basemap />
                    </div>}
                    <Help />
                    <Import />
                    {displayPrint && <Print selectedPrint={selectPrint} />}
                    {displayExport && <Export />}
                    <Toast ref={toast} position='bottom-center' style={{ zIndex: '9 !important' }} />
                </div>
            </DigiCallbackContextProvider>
        </>
    )
}