import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { useContext, useEffect, useState } from 'react'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Text from 'ol/style/Text';
import { MapContext } from '../../../map-container/map/MapContext';
import { useDispatch } from 'react-redux';
// import { openAddDigiPopup, setInputValues, setProvinceProperties } from '../../../../../../state/features/digitizationSlice';
import Snap from 'ol/interaction/Snap';
// import { useAppSelector } from '../../../../../../state/hooks';
// import { AddDigitization } from '../AddDigitization';
// import { DrawType, From, Mode, TransactWfs } from '../TransactWfs';
import { never } from 'ol/events/condition';
import WKT from 'ol/format/WKT';
import { useAppSelector } from '../../../../../state/hooks';
import { setCancelDigiActivate, setDigiPopupVisibility } from '../../../../../state/features/digitizationSlice';
import { DigiPopup } from '../digi-popup/DigiPopup';
import { DrawType, Mode, TransactWfs } from '../TransactWfs';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import { setSelectedFeature } from '../../../../../state/features/selectedFeatureSlice';
import Feature from 'ol/Feature';
// import { DigitizationRestApi } from '../../../../../../util/restapi/digitization';
// import { setIsDrawing } from '../../../../../../state/features/drawStateSlice';

/* 
 * Bu componentle line geometri tipinde bir feature oluşrutuyoruz.
 *
*/

interface Props {
    source: any
}

export const DigiDrawLinestring = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();
    const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility);
    const transactStatus = useAppSelector(state => state.digitization.transactStatus)
    const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate)
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const [feature, setFeature] = useState<any>(undefined)

    useEffect(() => {
        const vectorSource = props.source;
        const featureForParallel : any = vectorSource.getFeatures()[0]
        if(featureForParallel){ 
            const feature = new Feature()
            feature.setGeometry(featureForParallel.values_.geometry)
            setFeature(feature)
            dispatch(setDigiPopupVisibility(true))
        }
    }, [])
    
    useEffect(() => {
        let draw = new Draw({
            source: props.source,
            type: 'LineString',
            style: new Style({
                stroke: new Stroke({
                    color: 'red',
                    width: 3,
                }),
                fill: new Fill({
                    color: 'A52125',
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: 'red',
                    }),
                }),
            }),
            finishCondition: never,
            condition: function (e: any) {
                //sadece solla çizim yapıyor ve sağla bitiriyor
                if (e.originalEvent.buttons === 1) {
                    return true;
                } else if (e.originalEvent.buttons === 2) {
                    draw.finishDrawing();
                    return false;
                }
                else {
                    return false;
                }
            },
        });

        const snap = new Snap({
            source: props.source
        });

        map.addInteraction(snap);
        map.addInteraction(draw);

        draw.on('drawend', (event: any) => {
            map.removeInteraction(draw)
            const lineStringLength = event.feature?.getGeometry().getLength();
            setTextAfterDrawEnd(event, lineStringLength)
            setFeature(event.feature)
        });
        const content = map.getTargetElement();
        content.addEventListener('contextmenu', (event: MouseEvent) => {
            map.removeInteraction(draw);
        });

        return () => {
            map.removeInteraction(draw)
            props.source.clear();
        }

    }, [map])

    /**
     * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
     */
    const setTextAfterDrawEnd = (event: any, length: any) => {
        length = length.toFixed(2);

        dispatch(setDigiPopupVisibility(true))

        event.feature.setStyle(new Style({
            stroke: new Stroke({
                color: 'red',
                width: 3,
            }),
            fill: new Fill({
                color: 'A52125',
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'red',
                }),
            }),
            text: new Text({
                text: `${length}m`,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
            }),
        }));
    }

    useEffect(() => {
        if (cancelDigiActivate) {
            props.source.clear();
            map.updateSize();
            dispatch(setSelectedFeature([]))

            dispatch(setCancelDigiActivate(false));
            dispatch(setButtonState(ButtonState.NONE))
        }
    }, [cancelDigiActivate])

    return (
        <>
            {digiPopupVisibility && <DigiPopup mode={Mode.INSERT} title='Add New Feature' from='Map' />}
            {transactStatus && (buttonState === ButtonState.ADD || buttonState === ButtonState.PARALLEL) && <TransactWfs coordinates={feature.getGeometry().getCoordinates()} feature={feature} mode={Mode.INSERT} source={props.source} type={DrawType.LINESTRING} />}
        </>
    )
}
