import './Login.scss';
import { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { CustomizeRestApi } from '../../util/restapi/customize';
import { LoginRestApi } from '../../util/restapi/login';
import { useAppSelector } from '../../state/hooks';
import { useDispatch } from 'react-redux';
import { setCustomize, setToken } from '../../state/features/customizeSlice';
import { useNavigate } from "react-router-dom";
import { setLoginStatus, setToastVisibility } from '../../state/features/loginSlice';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { Button } from "primereact/button";

export const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useRef<any>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState<boolean>(false);
  const customize = useAppSelector((state: any) => state.customize.value)

  useEffect(() => {
    CustomizeRestApi.customize().then((res: any) => {
      if (res) {
        dispatch(setCustomize(res.data.customize));
      }
    })
    localStorage.setItem('activeLanguage', 'tr')
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault();
    LoginRestApi.login(username, password).then((res: any) => {
      if (res && res.data.token) {
        localStorage.setItem('token', res.data.token);
        dispatch(setToken(res.data.token))
        localStorage.setItem('username', username);
        dispatch(setLoginStatus('LOGIN'));
        dispatch(setToastVisibility(true));
        setTimeout(() => {
          navigate('/');
        }, 100);
      }
      else {
        dispatch(setLoginStatus('LOGOUT'));
      }
    }).catch((err: any) => {
      if (err.response?.status === 401) {
        toast.current?.show({ severity: 'error', detail: (t('LOGIN.Login Fail')), life: 3000 });
        localStorage.removeItem('token')
      } else if (err.response?.status === 400) {
        toast.current?.show({ severity: 'error', detail: (t('LOGIN.Please Fill in Username and Password Field')), life: 3000 });
        localStorage.removeItem('token')
      }
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit} className='login'>
        <div className='top'>
          <img src={customize.logo} alt="suen-logo" width='320px' />
        </div>
        <div className="header">
          <h3>Bu platform, “SUEN Portal” projesi kapsamında geliştirilmiştir.</h3>
        </div>
        <div className="login_contianer">

          <div className='right'>
            <div className="title">
              <h4>Sınıraşan Su Havzaları Bilgi Sisteminin (SUEN Portal) Geliştirilmesi Projesi Hakkında</h4>
            </div>
           
          </div>
          <div className='left'>
            <div className='line'>
              <span className='label text-color'>  {t('LOGIN.Username')}
              </span>
              <InputText value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className='line'>
              <span className='label text-color'> {t('LOGIN.Password')}</span>
              <Password type='password' value={password} onChange={(e) => setPassword(e.target.value)} toggleMask />
            </div>
            <div className='confirm'>
              <span className='label text-color'> {t('LOGIN.Remember me')}</span>
              <Checkbox inputId="binary" checked={checked} onChange={e => setChecked(e.checked)} />
            </div>
            <div className='login-button'>
              <Button label={t('LOGIN.Login')} type='submit' />
            </div>
          </div>
          <div className="bottom">
          <p className='description'> Enstitümüz bünyesinde 2011 yılından bu yana, sınıraşan havzalarımızın Ülkemiz dışında kalan kısımlarına yönelik
              coğrafi temelli bir çok veri toplanmış ve üretilmiştir.</p>
            <p>Hem Ülkemizin beş sınıraşan havzasına (Fırat-Dicle, Aras-Kura, Asi, Çoruh, Meriç) ait bu verilerin (nehirler, göller, <br />
              baraj/göletler, yerleşim yerleri, tarım alanları, arıtma tesisleri gibi) korunması hem de Enstitümüz personelinin <br />
              faydalabileceği bir platforma dönüştürülmesi hedeflenen bu projede, standartlaştırılmış bir veritabanı ile <br />
              kullanımı kolay, geliştirilmeye ve revizyona açık bir ağ platformu oluşturulmuştur.</p>
          </div>
        </div>


      </form>
      <Toast ref={toast} position='bottom-center' className='login-toast' style={{ zIndex: '9 !important' }} />
    </>
  )
}