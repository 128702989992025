import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env
// const token = localStorage.getItem('token');

const getToken = () => {
  return localStorage.getItem('token')
}

// async function getServices() {
//   return axios.get(REACT_APP_BASE_URL + `rest/v1/services`, { params: { token: token } })
// }

async function getLayerGroups() {
  return axios.get(REACT_APP_BASE_URL + `rest/v1/layerGroup`, { params: { token: getToken() } })
}
// async function getStyleById(styleId: any) {
//   return axios.get(REACT_APP_BASE_URL + 'rest/v1/style/byId/' + styleId)
// }
async function getStyles() {
  return axios.get(REACT_APP_BASE_URL + 'rest/v1/style', { params: { token: getToken() } })
}

const getStyleWithLayername = async (layername: string) => {
  return axios.get(`${REACT_APP_BASE_URL}rest/v1/style/${layername}`, { params: { token: getToken() } })
}
// async function getFeatures(layers: string[], addToUrl?: string, filter?: string) {
//   if (layers.length > 1) {
//     const layersString = layers.join(',');
//     const urlWfs =
//       REACT_APP_BASE_URL +
//       'geoserver/anadolu/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=' +
//       layersString +
//       '&' +
//       addToUrl +
//       '&' +
//       filter;
//     return axios.get(urlWfs);
//   }
//   else if (layers.length === 1) {
//     const urlWfs =
//       REACT_APP_BASE_URL +
//       'geoserver/anadolu/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=' +
//       layers;
//     return axios.get(urlWfs);
//   }
// }

const getGeojsonFromGetFeatureInfoUrlArray = async (urlArray: string[]) => {
  
  const result = await Promise.all(
    urlArray.map(url => axios.get(url).then(res => res.data.features))
  )
  return result;
}

export const LayerRestApi = {
  getLayerGroups,
  getStyles,
  getStyleWithLayername,
  getGeojsonFromGetFeatureInfoUrlArray,
}