import { useContext, useEffect, useState } from 'react'
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { MapContext } from '../../../map-container/map/MapContext';
import { Mode } from '../TransactWfs';
import { useDispatch } from 'react-redux';
import Draw from 'ol/interaction/Draw';
import { never } from 'ol/events/condition';
import { useAppSelector } from '../../../../../state/hooks';
import ConfirmPopup from '../confirm-popup/ConfirmPopup';
import { setCancelDigiActivate, setConfirmPopupVisibility } from '../../../../../state/features/digitizationSlice';
import { setSelectedFeature } from '../../../../../state/features/selectedFeatureSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';

export const DigiGeomDelete = () => {
    const map = useContext(MapContext);
    const dispatch = useDispatch()
    const [olFeature, setOlFeature] = useState<any>(new Feature())
    const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
    const [vectorLayer, setVectorLayer] = useState<VectorLayer<any>>(new VectorLayer())
    const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature)
    const confirmPopupVisibility: any = useAppSelector(state => state.digitization.confirmPopupVisibility)
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState);
    const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate)

    useEffect(() => {
        map.updateSize();
    }, [])

    const style = new Style({
        fill: new Fill({
            color: 'rgb(165, 33, 37, 0.44)',
        }),
        stroke: new Stroke({
            color: '#00FFFF',
            width: 6,
        }),
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({
                color: '#38EEF5',
            }),
        }),
    })

    useEffect(() => {
        if (selectedFeature) {
            for (const feature of selectedFeature) {

                if (feature.geometry) {
                    const vectorSource = new VectorSource();

                    const vector = new VectorLayer({
                        source: vectorSource,
                        zIndex: 10000000000000000,
                        style: style
                    });
                    map.addLayer(vector);
                    setVectorLayer(vector)
                    const geoJson = new GeoJSON()
                    const readFeature: any = geoJson.readFeature(feature)
                    vectorSource.addFeature(readFeature);
                    setSource(vectorSource)
                    if (readFeature && readFeature.getGeometry()) {
                        setOlFeature(readFeature);
                        dispatch(setConfirmPopupVisibility(true))

                    }
                    return () => {
                        map.removeLayer(vector);
                        dispatch(setSelectedFeature([]))
                        source.clear();
                    }
                }
            }
        }
    }, [selectedFeature])

    useEffect(() => {
        if (olFeature) {
            let draw = new Draw({
                source: source,
                type: 'Polygon',
                style: new Style({
                    fill: new Fill({
                        color: 'rgb(165, 33, 37, 0.44)',
                    }),
                    stroke: new Stroke({
                        color: '#A52125',
                        width: 2,
                    }),
                    image: new CircleStyle({
                        radius: 5,
                        fill: new Fill({
                            color: '#A52125',
                        }),
                    }),
                }),
                finishCondition: never,
                condition: function (e: any) {
                    //sadece solla çizim yapıyor ve sağla bitiriyor
                    if (e.originalEvent.buttons === 1) {
                        return true;
                    } else if (e.originalEvent.buttons === 2) {
                        draw.finishDrawing();
                        return false;
                    }
                    else {
                        return false;
                    }
                },
            });
        }
    }, [olFeature])

    useEffect(() => {
        if (cancelDigiActivate) {
            dispatch(setSelectedFeature([]))
            dispatch(setCancelDigiActivate(false));
            dispatch(setButtonState(ButtonState.NONE))
            source.clear();
        }
    }, [cancelDigiActivate])

    return (
        <>
            {confirmPopupVisibility && buttonState === ButtonState.DELETE && <ConfirmPopup title='Veri Sil' content='Silmek istediğinize emin misiniz?' from='Map' mode={Mode.DELETE} />}
        </>
    )
}
