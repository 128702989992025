import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { useContext, useEffect } from 'react'
import { MapContext } from '../MapContext'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Text from 'ol/style/Text';
import { never } from 'ol/events/condition';
import { setDrawState } from '../../../../../state/features/drawStateSlice';
import { DrawStateType } from '../../../../../util/model/drawState';
import { useDispatch } from 'react-redux';
import { WKT } from 'ol/format';
import { setMapCoordinates } from '../../../../../state/features/coordinatesSlice';
interface Props {
    source: any
}

export const DrawLinestring = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();

    useEffect(() => {
        let draw = new Draw({
            source: props.source,
            stopClick: true,
            type: 'LineString',
            style: new Style({
                stroke: new Stroke({
                    color: '#3ea1f1',
                    width: 3,
                }),
                fill: new Fill({
                    color: '#3ea1f1',
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: '#3ea1f1',
                    }),
                }),
            }),
            finishCondition: never,
            condition: function (e: any) {
                //sadece solla çizim yapıyor ve sağla bitiriyor
                if (e.originalEvent.buttons === 1) {
                    return true;
                } else if (e.originalEvent.buttons === 2) {
                    draw.finishDrawing();
                    return false;
                }
                else {
                    return false;
                }
            },

        });

        map.addInteraction(draw);

        draw.on('drawend', (event: any) => {
            const lineStringLength = event.feature?.getGeometry().getLength();
            setTextAfterDrawEnd(event, lineStringLength)
            dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
        });

        return () => {
            map.removeInteraction(draw)
        }

    }, [map])

    /**
     * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
     */
    const setTextAfterDrawEnd = (event: DrawEvent, length: any) => {
        const feature = event.feature;
        length = length.toFixed(2);

        feature.setStyle(new Style({
            stroke: new Stroke({
                color: '#3ea1f1',
                width: 3,
            }),
            fill: new Fill({
                color: '#3ea1f1',
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#3ea1f1',
                }),
            }),
            text: new Text({
                text: `${length}m`,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
            }),
        }));

        //aynı çizimi panorama üzerinde de göstermek için
        const wkt = new WKT();
        const wktFeature = wkt.writeFeature(feature)

        const result = {
            type: 'Point',
            feature: wktFeature,
            text: `${length}m`
        }

        dispatch(setMapCoordinates(result))
    }




    return (
        <>
        </>
    )
}
