import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { transform } from 'ol/proj';
import { Icon, Style } from 'ol/style';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MapContext } from '../../map-container/map/MapContext';
import { useAppSelector } from '../../../../state/hooks';
import { setMapCoordinate } from '../../../../state/features/panoramaSlice';
import { ButtonState } from '../../../shared/button-state/ButtonState';
import { setUrlCoordinate } from '../../../../state/features/urlSlice';
import { useLocation } from 'react-router-dom';

export default function PanoLook() {
    const panoramaCoordinate: any = useAppSelector(state => state.panorama.panoramaCoordinate)
    const layerGroups = useAppSelector((state) => state.layer.layerGroups);
    // const angle = useAppSelector((state) => state.panorama.angle)
    const buttonState = useAppSelector(state => state.buttonState.buttonState)
    const location = useLocation();

    const [context, setContext] = useState<CanvasRenderingContext2D | undefined>(undefined)
    const [storage, setStorage] = useState<any>(undefined)
    const [feature, setFeature] = useState<any>(null)
    const [canvasValue, setCanvasValue] = useState<any>(null)

    const map = useContext(MapContext);
    const dispatch = useDispatch();
    let canvas: HTMLCanvasElement;
    let radius: number = 50;
    let layer: VectorLayer<any>;
    let f: Feature<Geometry>;
    let ctx: CanvasRenderingContext2D;
    let color: string = '#ff0003c9';
    let style: Style;

    useEffect(() => {
        createCanvas();
        layer = createLayer();
        map.addLayer(layer)
        createStyle();
        createFeature();
        map.updateSize();
        return () => {
            map.removeLayer(layer);
        }
    }, [])

    useEffect(() => {
        setStorage(location.pathname.split('@View')[1]);
    }, [location])

    useEffect(() => {
        let visible = false;
        const panogpsLayerGroup: any = layerGroups.find((layer: any) => layer.name === 'panogps');
        if (panogpsLayerGroup) {
            const layerArray = panogpsLayerGroup.layers
            visible = layerArray.find((layer: any) => layer.visible ? true : false)
        }

        if (
            buttonState !== ButtonState.INFO &&
            buttonState !== ButtonState.ADD &&
            buttonState !== ButtonState.EDIT &&
            buttonState !== ButtonState.VERTEX &&
            buttonState !== ButtonState.DELETE &&
            buttonState !== ButtonState.SELECTION) {
            map.on('click', mapClick);
        }
        return () => {
            map.un('click', mapClick);
        }
    }, [layerGroups, buttonState])

    useEffect(() => {
        if (canvasValue && storage) {
            const value = storage.split(',')
            createCanvasContent(value[0]);
        }
    }, [canvasValue, storage])

    useEffect(() => {
        if (panoramaCoordinate.lat && feature) {
            const coord = [panoramaCoordinate.lon, panoramaCoordinate.lat];
            dispatch(setUrlCoordinate(coord))
            const locationTransform = transform(coord, 'EPSG:4326', 'EPSG:3857');
            dispatch(setMapCoordinate(locationTransform))
            feature.setGeometry(new Point(locationTransform))
        }
    }, [panoramaCoordinate])



    useEffect(() => {
        if (storage) {
            const value = storage.split(',')
            if (value[2] && feature) {
                const radius = value[2] * (Math.PI / 180);
                if (feature) {
                    feature!.getStyle().getImage().setRotation(radius);
                    feature.notify();
                }
            }

            if (canvasValue && context) {
                context.clearRect(0, 0, canvasValue.width, canvasValue.height);
                createCanvasContent(value[0]);
                feature.notify();
            }
        }
    }, [storage, feature])

    // useEffect(() => {
    //     const storage: any = localStorage.getItem('view')?.split(',')
    //     setStorage(storage)

    // }, [localStorage.getItem('view')])


    const mapClick = (event: any) => {
        const clickedCoordinate = event.coordinate;
        if (clickedCoordinate) {
            dispatch(setMapCoordinate(clickedCoordinate));
        }
    }

    const createCanvas = () => {
        canvas = document.createElement('canvas');
        canvas.width = radius * 2;
        canvas.height = radius * 2;
        setCanvasValue(canvas);
    }

    const createCanvasContent = (fov: number) => {
        const degree = fov * 100;
        const startDegree = 270 - (degree / 2);
        const endDegree = startDegree + degree;

        ctx = canvasValue.getContext('2d')!;
        ctx.beginPath();
        ctx.moveTo(radius, radius);
        ctx.arc(radius, radius, radius, startDegree * (Math.PI / 180), endDegree * (Math.PI / 180));
        ctx.closePath();
        ctx.fillStyle = color;
        ctx.fill();
        setContext(ctx);
    }

    const createStyle = () => {
        const image = new Icon({
            img: canvas,
            rotateWithView: true,
            imgSize: [radius * 2, radius * 2],
            opacity: 0.8
        });
        style = new Style({
            zIndex: 1000000,
            image
        });
    }

    const createLayer = () => {
        return new VectorLayer({ source: new VectorSource(), zIndex: 200000000 });
    }

    const createFeature = () => {
        f = new Feature();
        f.setStyle(style);
        const coord = [panoramaCoordinate.lon, panoramaCoordinate.lat];
        const locationTransform = transform(coord, 'EPSG:4326', 'EPSG:3857');
        const point = new Point(locationTransform);
        f.setGeometry(point);
        layer.getSource().addFeature(f);
        setFeature(f)
    }

    return (
        <>
            <div className='PanoLook'></div>
        </>
    )
}
