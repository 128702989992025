
import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getToken = () => {
    return localStorage.getItem('token')
}

async function getProjects() {
    return axios.get(REACT_APP_BASE_URL + 'rest/v1/project/', { params: { token: getToken() } });
}

export const ProjectRestApi = {
    getProjects
}