import WFS from 'ol/format/WFS';
import GML from 'ol/format/GML';
import MultiPolygon from 'ol/geom/MultiPolygon';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiLineString from 'ol/geom/MultiLineString';
import Feature from 'ol/Feature';
import { useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import { DigiCallbackContext } from './DigiCallbackContextProvider';
import { GeoJSON } from 'ol/format';
import { MapContext } from '../../map-container/map/MapContext';
import { useAppSelector } from '../../../../state/hooks';
import { DigitizationRestApi } from '../../../../util/restapi/digitization';
import {
  setDigiPopupVisibility,
  setGeomEditStatus,
  setPanoramaTransactStatus,
  setPopupSaveStatus,
  setTransactStatus,
} from '../../../../state/features/digitizationSlice';
import { FeatureRestApi } from '../../../../util/restapi/features';
import { setFeatures } from '../../../../state/features/tableSlice';
import { setSelectedFeature } from '../../../../state/features/selectedFeatureSlice';
import { ButtonState } from '../../../shared/button-state/ButtonState';
import { setButtonState } from '../../../../state/features/buttonStateSlice';
import Polygon from 'ol/geom/Polygon';

export enum Mode {
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}
export enum GeometryType {
  MULTIPOINT = 'MultiPoint',
  MULTILINESTRING = 'MultiLineString',
  MULTIPOLYGON = 'MultiPolygon',
}

interface Props {
  feature?: any;
  mode: Mode;
  source?: any;
  type: any;
  coordinates?: any;
}

export const TransactEdit = (props: Props) => {
  const dispatch = useDispatch();
  const map = useContext(MapContext);
  const digiCallback = useContext(DigiCallbackContext);
  const [selectedFeature]: any = useAppSelector((state) => state.selectedFeature.feature);
  const services: any = useAppSelector((state) => state.layer.services);

  let layerAndWorkspace: any;

  useEffect(() => {

    const olGeoJson = new GeoJSON();

    const geoJsonFinal: any = olGeoJson.writeFeatureObject(props.feature);

    delete geoJsonFinal.properties.bbox;

    const newFeature: any = new Feature();
    newFeature.setProperties(geoJsonFinal.properties);
    newFeature.setGeometryName('geom');
    newFeature.setId(geoJsonFinal.id);

    const type = geoJsonFinal.geometry.type;

    let newGeom: any;

    if (type === GeometryType.MULTIPOINT) {
      newGeom = new MultiPoint(geoJsonFinal.geometry.coordinates);
    } else if (type === GeometryType.MULTILINESTRING) {
      newGeom = new MultiLineString(geoJsonFinal.geometry.coordinates);
    } else if (type === GeometryType.MULTIPOLYGON) {
      newGeom = new MultiPolygon(geoJsonFinal.geometry.coordinates);
    } else if (type === 'MultiPolygon') {
      newGeom = new Polygon(props.feature.getCoordinates());
    }

    newFeature.setGeometry(newGeom);

    newFeature.unset('bbox', true);
    newFeature.unset('key', true);
    newFeature.unset('label', true);
    //@ts-ignore

    layerAndWorkspace = getLayerAndService(selectedFeature.id, services);

    const formatWFS: any = new WFS();

    const formatGML = new GML({
      featureNS: layerAndWorkspace.service.workspace,
      featureType: layerAndWorkspace.layer.name,
      srsName: 'EPSG:3857',
      hasZ: true,
    })

    const xs = new XMLSerializer();

    const node = formatWFS.writeTransaction(null, [newFeature], null, formatGML);

    const payload = xs.serializeToString(node)

    DigitizationRestApi.digiAddFeature(payload, layerAndWorkspace.service.url).then((res: any) => {
      if (res) {
        dispatch(setPanoramaTransactStatus(false))
      }

      const featureId = res.split('.')[1]
      // if (typeof (window as any).addDigiCallback === 'function') {
      //     (window as any).addDigiCallback(featureId)
      // }
      if (typeof digiCallback.callbackFunction === 'function') {
        digiCallback.callbackFunction(featureId)
      }

      // FeatureRestApi.getFeatures(layerAndWorkspace.service.url, layerAndWorkspace.layer.name).then((res: any) => {
      //   if (res) {
          /* dispatch(setFeatures(res.data.features)) */

          // map update
          const layersOfMap = map.getLayers();

          layersOfMap.forEach((layer: any) => {
            let source: any = layer.getSource();
            if (source.updateParams !== undefined)
              source.updateParams({ time: Date.now() });
          });

          props.source?.clear()

          dispatch(setPanoramaTransactStatus(undefined))
          dispatch(setTransactStatus(undefined))
          dispatch(setPopupSaveStatus(undefined))
          dispatch(setSelectedFeature([]))
          dispatch(setGeomEditStatus(false))
          dispatch(setDigiPopupVisibility(false))
          dispatch(setSelectedFeature([]))
          dispatch(setButtonState(ButtonState.NONE))
          map.updateSize();
          map.render();
      //   }
      // })
    })
  }, [props.feature]);

  const getLayerAndService = (featureId: string, services: any) => {
    let service;
    let layer;
    const layerName = featureId.split('.')[0]

    for (const item of services) {
      for (const testLayer of item.layers) {
        if (testLayer.name === layerName) {
          layer = testLayer
          service = item
        }
      }
    }
    const obj = {
      layer: layer,
      service: service
    }
    return obj
  }

  return (
    <>
    </>
  )
}