import './Basemap.scss';
import { useAppSelector } from '../../state/hooks';
import { changeVisibility } from '../../state/features/basemapSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

export const Basemap = () => {
    const basemaps = useAppSelector(state => state.basemaps.value);
    const dispatch = useDispatch()
    const [showBasemaps, setShowBasemaps] = useState<Boolean>(false);
    const [selectedBasemap, setSelectedBasemap] = useState<any>(null);

    const changeBasemapVisibility = (index: number, visibility: boolean) => {
        dispatch(changeVisibility({ index, visibility }))
    }

    useEffect(() => {
        if (basemaps.length > 0) {
            const selectedBasemap = basemaps.find((basemap: any) => {
                return basemap.visible === true
            })
            setSelectedBasemap(selectedBasemap)
        }
    }, [basemaps]);
    
    return (
        <div className='Basemap non-printable'>
        <div className='basemap-button' onClick={() => setShowBasemaps(!showBasemaps)}>
            {selectedBasemap && <img className='image-box' src={selectedBasemap.icon} alt='googleStreet' />
            }
        </div>
        {showBasemaps && <div className='basemap-group'>
            {basemaps.length > 0 && basemaps.map((basemap: any, index: number) =>
                <div className='basemap' key={index} onClick={() => changeBasemapVisibility(index, true)}>
                    <img className='image-box' src={basemap.icon} alt='googleStreet' />
                </div>
            )}
        </div>}
    </div>
    )
}
