import axios from 'axios';


const getToken = () => {
    return localStorage.getItem('token')
}

const getFeatures = async (url: string, layerName: any) => {
    return axios.get(url + '/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=' + layerName, { params: { token: getToken() } });
}

export const FeatureRestApi = {
    getFeatures
}