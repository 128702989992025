import { createSlice } from '@reduxjs/toolkit';
import { ICustomize } from '../../util/model/customize';

interface CustomizeState {
    value: ICustomize,
    token: any
}
const initialState: CustomizeState = {
    value: {
        id: 0,
        logo: '',
        primarycolor: '',
        secondarycolor: '',
        startextent: '' || null,
    },
    token: localStorage.getItem('token')
}

export const customizeSlice = createSlice({
    name: 'customize',
    initialState,
    reducers: {
        setCustomize: (state: any, action) => {
            state.value = action.payload;
        },
        setToken: (state: any, action) => {
            state.token = action.payload;
        }
    }
})

export const { setCustomize, setToken } = customizeSlice.actions

export default customizeSlice.reducer