import './Information.scss'
import { Dialog } from 'primereact/dialog';
import { useAppSelector } from '../../../state/hooks';
import { useDispatch } from 'react-redux';
import { setSelectedFeature } from '../../../state/features/selectedFeatureSlice';
import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { AttachmentRestApi } from '../../../util/restapi/attachment';
import { Image } from 'primereact/image';
import { useTranslation } from 'react-i18next';
import saveAs from 'file-saver';
import { Layer, Project } from '../../../util/model/project';
import { RootState } from '../../../state/store';
import { ButtonState } from '../button-state/ButtonState';
import axios from 'axios';
import { setButtonState } from '../../../state/features/buttonStateSlice';

export const Information = () => {

    const sidebarVisibility = useAppSelector(state => state.buttonState.sidebarButton);
    const buttonState = useAppSelector(state => state.buttonState.buttonState)
    const selectFeature: any = useAppSelector(state => state.selectedFeature.feature);
    const selectedLayerInfo: any = useAppSelector(state => state.info.selectedLayerInfo);
    const layerGroups: any = useAppSelector(state => state.layer.layerGroups);
    const domainFields: any = useAppSelector(state => state.domain.domainFields);
    const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects);
    const selectedFeature = selectFeature[0]

    const { t } = useTranslation();
    const featureArray: any = [];
    const dispatch = useDispatch();
    const [featureArr, setFeatureArray] = useState<any>({});
    const [attachmentArr, setAttachmentArr] = useState<any>({});
    const token = useAppSelector((state: any) => state.customize.token);
    const [allAttachments, setAllAttachments] = useState<any>([]);
    const [feature, setFeature] = useState<any>({});
    const [attachmentArray, setAttachmentArray] = useState<any>({});
    const [layer, setLayer] = useState<any>({})
    const [user, setUser] = useState<string>("")
    const [touchUser, setTouchUser] = useState<string>("")

    const onHide = () => {
        dispatch(setSelectedFeature([]))
        // dispatch(setButtonState(ButtonState.NONE))
    }

    useEffect(() => {
        if (selectedFeature) {
            setAllAttachments([])
            setAttachmentArr([])
            setFeatureArray([])
            let feature = selectedFeature.properties;
            //get attachment
            const id = (selectedFeature.id).split('.')[1];
            const layerName = (selectedFeature.id).split('.')[0];
            projects.map((project: Project) => {
                project.layer_groups.map((layerGroup: any) => {
                    layerGroup.layers.map((layer: Layer) => {
                        if (layer.name === layerName) {
                            setLayer(layer)
                            AttachmentRestApi.getAttachmentOfFeature(layer.id, +id).then((res) => {
                                const response = res.data
                                if (response.length > 0) {
                                    const pattern = /[\/.](gif|jpg|jpeg|tiff|png|svg)$/;
                                    response.forEach((item: any) => {
                                        const isValidImage = pattern.test(item.filename.toLowerCase());
                                        const url2 = generateLink(item)
                                        item.imageUrl = isValidImage ? url2 : null;
                                        item.downloadUrl = url2 + `&download=true`;
                                        item.isValidImage = isValidImage;
                                        setAllAttachments(response)
                                        setFeature(feature)
                                    });
                                }
                                else {
                                    setFeature(feature)
                                }
                            })
                        }
                    })
                })
            })


        }
    }, [selectedFeature, selectedLayerInfo])

    useEffect(() => {
        if (!feature) {
            return
        }

        if (layer.id) {

            const selectedLayerName = selectedFeature.id.split('.')[0];

            let clonedFeature = Object.assign({}, feature)
            // layerGroups.map((layerGroup: any) => {
            //     const selectedLayer = layerGroup.layers.find((layer: any) => layer.name === selectedLayerName)
            //     setLayer(selectedLayer)

            if (layer) {
                const domainFields = layer.fields.filter((field: any) => field.type === 'Domain')
                const fieldsOfLayer = domainFields.find((domainField: any) => domainField.layer_id === layer.id)
                if (fieldsOfLayer && fieldsOfLayer.domain) {
                    for (const domain of fieldsOfLayer.domain) {
                        // for (const domain of domains.domain) {
                        if (domain.id === feature[domain.name]) {
                            clonedFeature = { ...clonedFeature, [domain.name]: domain.desc }

                        }
                        // }
                    }
                }
            }
            // })

            //get alias for labels
            if (layer && layer.fields && layer.fields.length > 0) {
                for (const field of layer.fields) {
                    for (const [key, value] of Object.entries(clonedFeature)) {
                        if (key !== 'bbox') {
                            if (field.name === key) {
                                featureArray.push({ label: field.alias, value: value, priority: field.priority })
                            }
                        }
                    }
                    if (field.type === 'Attachment') {
                        setAttachmentArray([{ label: field.alias, value: allAttachments }])
                    }
                }
            }

            //sort
            // const sortedFeatureArray = featureArray;
            const sortedFeatureArray = featureArray.sort((a: any, b: any) => a.priority - b.priority)
            // const sortedFeatureArray = featureArray.sort((a: any, b: any) => {
            //     return (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0;
            // });


            if (sortedFeatureArray.length) {
                setFeatureArray(sortedFeatureArray)

                const createdUser = sortedFeatureArray.find((el: any) => el.label === 'Created By')
                    
                if(createdUser && createdUser.value){
                    axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/user/${createdUser.value}?token=${token}`)
                    .then((response: any) => {
                        setUser(response.data.user.username)
                    })
                }else{
                    setUser("")
                }

                const touchUser =  sortedFeatureArray.find((el: any) => el.label === 'touch By');
                if(touchUser && touchUser.value){
                    axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/user/${touchUser.value}?token=${token}`)
                    .then((response: any) => {
                        setTouchUser(response.data.user.username)
                    })
                }else{
                    setTouchUser("")
                }

            }

        }
    }, [feature, layer])

    useEffect(() => {
        if (attachmentArray.length > 0 && attachmentArray[0].value.length > 0) {
            attachmentArray[0].value.sort((a: any, b: any) => {
                return (a.isValidImage === b.isValidImage) ? 0 : a.isValidImage ? -1 : 1;
            });
            setAttachmentArr(attachmentArray)
        }
    }, [attachmentArray])

    const generateLink = (attachment: any) => {
        const { REACT_APP_BASE_URL } = process.env
        return REACT_APP_BASE_URL + `rest/v1/attachment/${attachment.id}?token=${token}`
    }

    const downloadFile = (item: any) => {
        saveAs(item.downloadUrl);
    }

    return (
        <>
            {featureArr && featureArr.length > 0 && <Dialog header={layer.alias + ' ' + selectedFeature.id.split('.')[1]} visible={buttonState === ButtonState.INFO} position='right' modal={false} className={sidebarVisibility ? 'dialog-box-sidebar-open' : 'dialog-box'} onHide={onHide}
                draggable={true} resizable={true}>
                {featureArr && featureArr.length > 0 && featureArr.map((feature: any) =>
                    <div key={feature.id} className='line'>
                        <p className='label'>{feature.label}:</p>
                        {
                            (feature.label === 'Created Date' || feature.label === 'touch Date') ?
                                <InputText value={feature.value ? new Date(feature.value).toISOString().substring(0, 10).split('-').reverse().join('-') : '-'} disabled />
                                :
                                feature.label === 'Created By'
                                    ?
                                    <InputText value={user} disabled />
                                    :
                                    feature.label === 'touch By'
                                    ?
                                    <InputText value={touchUser} disabled />
                                    :
                                    <InputText value={feature.value ? feature.value : '-'} disabled />
                        }
                    </div>
                )}
                {allAttachments && allAttachments.length > 0 &&
                    <div className="attachment-line">
                        {
                            attachmentArr.map((file: any) =>
                                <div key={file.label} className='file-group'>
                                    <p className='label'>{file.label}:</p>
                                    <div className='file-box'>
                                        {(file.value).length > 0 && file.value.map((val: any) =>
                                            (val.file_type).includes('image') ?
                                                <div key={val.id} className='image-container'>
                                                    <Image
                                                        src={val.imageUrl}
                                                        alt={val.file_type}
                                                        preview
                                                    />
                                                    <div key={val.id} className='file-name-container'>
                                                        <div className='name file-name'>
                                                            {val.name}
                                                        </div>
                                                    </div>
                                                    <div className='button-container'>
                                                        <a className='anim anim-from-left' onClick={() => downloadFile(val)} download>
                                                            {t('BUTTON.Download')}
                                                        </a>
                                                    </div>
                                                </div>
                                                :
                                                <div key={val.id + 's'} className='file-container'>
                                                    <Image
                                                        src='customize-icons/file.svg'
                                                        alt={val.file_type}
                                                        className='file-icon'
                                                    />
                                                    <div className='file-name-container'>
                                                        <div className='file-name'>
                                                            {val.filename}
                                                        </div>
                                                    </div>
                                                    <div className='button-container'>
                                                        <a className='anim anim-from-left' onClick={() => downloadFile(val)} download>
                                                            {t('BUTTON.Download')}
                                                        </a>
                                                    </div>
                                                </div>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    </div>}
            </Dialog>}
        </>
    )
}
