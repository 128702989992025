import { useContext, useEffect, useState } from "react";
import { MapContext } from "../../../map-container/map/MapContext";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Draw } from "ol/interaction";
import { never } from "ol/events/condition";
import { DrawEvent } from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import { useAppSelector } from "../../../../../state/hooks";
import lineIntersect from '@turf/line-intersect';
import lineSplit from '@turf/line-split';
import { lineString as turfLineString } from '@turf/helpers';
import { transform } from "ol/proj";
import WKT from "ol/format/WKT";
import axios from "axios";
import { Feature as olFeature } from 'ol';
import { GeoJSON } from 'ol/format';
import { MultiLineString, LineString } from 'ol/geom';
import { /*MultiMeasurement,*/ GeomDataHolder, AnkaScalable } from '../../../panorama-container/easy-pano-imports';
import WFS from 'ol/format/WFS';
import GML from 'ol/format/GML';
import { DigitizationRestApi } from "../../../../../util/restapi/digitization";
import { setPanoramaTransactStatus, setPopupSaveStatus } from "../../../../../state/features/digitizationSlice";
import { DigiCallbackContext } from "../DigiCallbackContextProvider";
import { useDispatch } from 'react-redux';
import { FeatureRestApi } from "../../../../../util/restapi/features";
import { setFeatures } from "../../../../../state/features/tableSlice";
import { setSelectedFeature } from "../../../../../state/features/selectedFeatureSlice";
import { setButtonState } from "../../../../../state/features/buttonStateSlice";
import { ButtonState } from "../../../../shared/button-state/ButtonState";
import { useTranslation } from 'react-i18next';
import { Mode } from "../TransactWfs";
import { DigiPopup } from "../digi-popup/DigiPopup";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

enum DrawToolEventType {
  DRAWSTART,
  DRAWEND,
  DRAWABORT
}
interface DrawToolEvent {
  feature: Feature;
  type: DrawToolEventType;
}
interface Layer {
  name: string;
  alias: string;
  fields: any[];
  visible: boolean;
  perm?: number;
  style: number;
  description?: string;
  geomtype?: string;
  icon?: string;
  priority: number;
  id: number;
  panoStyle: any;
  style_id: number;
  type?: string;
  workspace?: string;
  url?: string;
  service_id: number;
  service_name: string;
}
enum FiledTypes {
  INTEGER = 'Integer',
  TEXT = 'Text',
  FLOAT = 'Float',
  DOMAIN = 'Domain',
  CREATEDBY = 'CreatedBy',
  CREATEDDATE = 'CreatedDate',
  DATE = 'Date',
  LENGTH = 'Length',
  LOCATION = 'Location',
  LOCATION_X = 'Location(X)',
  LOCATION_Y = 'Location(Y)',
  LOCATION_Z = 'Location(Z)',
  AREA = 'Area',
  COLOR = 'Color',
  TELEPHONE = 'Telephone',
  EMAIL = 'Email',
  START_POINT = 'Start_Point',
  MIDDLE_POINT = 'Middle_Point',
  END_POINT = 'End_Point',
  START_POINT_X = 'Start_Point(X)',
  MIDDLE_POINT_X = 'Middle_Point(X)',
  END_POINT_X = 'End_Point(X)',
  START_POINT_Y = 'Start_Point(Y)',
  MIDDLE_POINT_Y = 'Middle_Point(Y)',
  END_POINT_Y = 'End_Point(Y)',
  START_POINT_Z = 'Start_Point(Z)',
  MIDDLE_POINT_Z = 'Middle_Point(Z)',
  END_POINT_Z = 'End_Point(Z)',
  PERIMETER = 'Perimeter',
  ATTACHMENT = 'Attachment',
  HEIGHT = 'Height',
}
export enum Operation {
  INSERT = 'Insert',
  UPDATE = 'Update',
  DELETE = 'Delete',
}

/**
* Type of geometry of feature of GeoJson
*/
export enum GeometryType {
  POINT = 'Point',
  LINESTRING = 'LineString',
  POLYGON = 'Polygon',
  MULTIPOINT = 'MultiPoint',
  MULTILINESTRING = 'MultiLineString',
  MULTIPOLYGON = 'MultiPolygon',
}

/**
* Projection code of geometry of feature of GeoJson
*/
export enum SrsName {
  EPSG_4326 = 'EPSG:4326',
  EPSG_3857 = 'EPSG:3857',
}

/**
* Dimensions of geometry of feature of GeoJson
* Latitude and longitude are imperative
* Altitude is optinal
*/
export enum SrsDimension {
  TWO_DIMENSION = '2',
  THREE_DIMENSION = '3',
}
interface IOptions {
  operation: Operation;
  workspace: string;
  typeName: string;
  geometryName?: string;
  srsName?: SrsName;
  srsDimension?: SrsDimension;
}
export const SplitLine = () => {
  const confirmPopupVisibility: any = useAppSelector(state => state.digitization.confirmPopupVisibility)
  const popupSaveStatus: any = useAppSelector(state => state.digitization.popupSaveStatus)
  const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const map = useContext(MapContext)
  const services: any = useAppSelector(state => state.layer.services)
  // console.log("services :",services)
  const [selectedFeature]: any = useAppSelector(state => state.selectedFeature.feature)
  // console.log("selectedFeature :",selectedFeature)

  const [line, setLine] = useState<any>(null)
  // const [layerAndWorkspace, setLayerAndWorkspace] = useState<any>({})
  let layerAndWorkspace: any;
  let formatGML: any
  const { REACT_APP_BASE_URL } = process.env
  const [attachmentFieldName, setAttachmentFieldName] = useState<any>()


  // const [geoJsonFeatureAr, setGeoJsonFeatureAr] = useState<any>([])

  // const [geoJsonFinals, setGeoJsonFinals] = useState<any>([])
  // const [geomfields, setGeomfields] = useState<any>([])
  let geomfields: any = []
  const digiCallback = useContext(DigiCallbackContext);
  // console.log("digiCallback:",digiCallback)

  const serviceLayersMap: Map<string, Layer> = new Map<string, Layer>()
  const [unmounted, setUnmounted] = useState<boolean>(false)

  useEffect(() => {
    // console.log("SplitLine");
    const layer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 100000000000000,
      properties: {
        alias: "LineSplit"
      }
    });
    map.addLayer(layer);

    const source: any = layer.getSource();
    const draw = new Draw({
      maxPoints: 2,
      source,
      type: 'LineString',
      stopClick: true,
      // style: selectedStyle,
      finishCondition: never,
      condition: (e): any => {
        switch (e.originalEvent.which) {
          case 1:
            return true;
          case 3:
            draw.finishDrawing();
            break;
          default:
            break;
        }
      }
    });

    draw.on('drawend', (e: DrawEvent) => onDrawEvent(e, DrawToolEventType.DRAWEND));
    draw.on('drawabort', (e: DrawEvent) => onDrawEvent(e, DrawToolEventType.DRAWABORT));
    draw.on('drawstart', (e: DrawEvent) => onDrawEvent(e, DrawToolEventType.DRAWSTART));
    // setDraw(draw)
    map.addInteraction(draw);

    return () => {
      map.removeInteraction(draw)
      map.removeLayer(layer)
      dispatch(setPopupSaveStatus(false));
      setUnmounted(true)
    }
  }, [])

  // useEffect(() => {
  //   if (popupSaveStatus && !unmounted) {
  //     cutterDrawEvent()
  //   }
  // }, [popupSaveStatus/*, unmounted*/])

  // useEffect(() => {
  // if (line) {
  // console.log("line ->",line)
  // dispatch(setDigiPopupVisibility(true))
  //   if (window.confirm("cutActionStart")) {
  //     cutterDrawEvent()
  //   //  const layerAndWorkspace = 
  // cutterDrawEvent()
  // }

  // }
  // }, [line])

  const waitForResult: any = (wktToGdh: any) => {
    return new Promise((resolve) => {
      const gdh = GeomDataHolder.fromWKT(wktToGdh.wkt);
      let geometry;
      let result;
      if (wktToGdh.geomtype === 'LineString') {
        geometry = new AnkaScalable.SLine((window as any).panogl.getScalable(), gdh);
        geometry.setPoints(gdh.points);
        const length = geometry.getLength();
        result = parseFloat(length.toFixed(2));
      }
      resolve(result)
    })
  }

  const calculateGeometricalFields = async (geojson: any, layerAndWorkspace: any) => {
    const fields = getFieldsByService(layerAndWorkspace.service.name, layerAndWorkspace.layer.name)
    const geometricalFields = fields.filter((field: any) =>
      field.type === FiledTypes.AREA ||
      field.type === FiledTypes.LENGTH ||
      field.type === FiledTypes.PERIMETER ||
      field.type === FiledTypes.START_POINT ||
      field.type === FiledTypes.MIDDLE_POINT ||
      field.type === FiledTypes.END_POINT ||
      field.type === FiledTypes.LOCATION)

    if (geometricalFields.length === 0) { return geojson }

    const wkt = new WKT()
    let geomWkt;
    let geom;
    switch (geojson.geometry.type) {
      case 'MultiLineString':
        geom = new LineString(geojson.geometry.coordinates[0])
        geom.transform('EPSG:3857', 'EPSG:4326')
        geomWkt = wkt.writeGeometry(geom)
        const coordLine4326 = (wkt.readGeometry(geomWkt) as any).getCoordinates()
        for (const field of geometricalFields) {
          if (field.type === FiledTypes.LENGTH) {
            const wktForGdh = {
              wkt: geomWkt,
              geomtype: 'LineString'
            }
            const result = await waitForResult()
            geojson.properties[field.name] = result
            // this.subscription.unsubscribe()
          }
          if (field.type === FiledTypes.START_POINT) {
            geojson.properties[field.name] = `${coordLine4326[0][0].toFixed(6)}, ${coordLine4326[0][1].toFixed(6)}, ${coordLine4326[0][2].toFixed(2)}`
          }
          if (field.type === FiledTypes.MIDDLE_POINT) {
            const response: { middlePoint?: any } = await getMiddlePointOfLine(geomWkt)
            geojson.properties[field.name] = response.middlePoint
          }
          if (field.type === FiledTypes.END_POINT) {
            geojson.properties[field.name] = `${coordLine4326[coordLine4326.length - 1][0].toFixed(6)}, ${coordLine4326[coordLine4326.length - 1][1].toFixed(6)}, ${coordLine4326[coordLine4326.length - 1][2].toFixed(2)}`
          }
        }
        break;
    }
    return new Promise((resolve) => {
      resolve(geojson)
    })
  }
  const cutLines = (geoJsonFinal: any, options: any): Promise<boolean> => {
    // console.log("geoJsonFinal :",geoJsonFinal);

    return new Promise((resolve, reject) => {


      let newFeature = new Feature()
      newFeature.setProperties(geoJsonFinal.properties)
      newFeature.setGeometryName('geom')
      const linestring = new MultiLineString(geoJsonFinal.geometry.coordinates);
      // linestring.appendLineString(geoJsonFinal.geometry)
      newFeature.setGeometry(linestring)


      let formatWFS: any = new WFS();


      formatGML = new GML({
        featureNS: layerAndWorkspace.service.workspace,
        featureType: layerAndWorkspace.layer.name,
        srsName: 'EPSG:3857',
        hasZ: true,
      });

      let xs = new XMLSerializer();

      let node: any;

      node = formatWFS.writeTransaction([newFeature], null, null, formatGML);
      let payload = xs.serializeToString(node)


      DigitizationRestApi.digiAddFeature(payload, layerAndWorkspace.service.url).then((res: any) => {

        if (res) {
          // dispatch(setToastMessageStatus(true))
          dispatch(setPanoramaTransactStatus(false))
        }
        // insert yapiliyorsa geoservera istek yapip featureId yi alacak o id ile attachment istegi yapacak

        const featureId = res.split('.')[1]
        // if (typeof (window as any).addDigiCallback === 'function') {
        //     (window as any).addDigiCallback(featureId)
        // }
        if (typeof digiCallback.callbackFunction === 'function') {
          digiCallback.callbackFunction(featureId)
        }
        resolve(true);
      })
    });
  }
  const calc = (geoJsonFeature: any, lengthName: any): Promise<any> => {
    return new Promise((resolve, reject) => {

      calculateGeometricalFields(geoJsonFeature, layerAndWorkspace)
        .then((geojson: any) => {

          let geoJsonFinal;
          // console.log("geojson :",geojson);

          geoJsonFinal = geojson;

          geoJsonFinal.properties[lengthName] =
            geojson.properties[lengthName].result;
          // delete geoJsonFinal.properties[lengthName]
          // console.log(' geoJsonFinal : ', geoJsonFinal);
          resolve(geoJsonFinal);
        });
    });
  }

  const cutterDrawEvent = async () => {
    // console.log("layerAndWorkspace:",getLayerAndService(selectedFeature.id, services));

    layerAndWorkspace = getLayerAndService(selectedFeature.id, services)

    const linestring1: any = turfLineString(
      line
        .transform('EPSG:3857', 'EPSG:4326')
        .getCoordinates()
    );

    // console.log("selectedFeaturev:",selectedFeature);
    const line_: any = new MultiLineString(selectedFeature.geometry.coordinates)
    // console.log("line_:",line_);

    const coords = line_
      .transform('EPSG:3857', 'EPSG:4326')
      .getCoordinates()[0];
    // console.log("coords: ",coords);

    const coordsXY = [];
    const alt = coords[0][2];
    for (let i = 0; i < coords.length; i++) {
      coordsXY.push([coords[i][0], coords[i][1]]);
    }
    const multiLine: any = turfLineString(coordsXY);
    const intersects = lineIntersect(linestring1, multiLine);
    if (intersects.features.length === 0) {
      // this.translateService
      //   .get('intersectionNotFound')
      //   .subscribe((response) => {
      //     this.snackbarService.showSnackbar('warning', response);
      //   });
      return;
    }
    const split = lineSplit(multiLine, linestring1)
    // console.log("layerAndWorkspace ",layerAndWorkspace);

    const fields = getFieldsByService(
      layerAndWorkspace.service.name,
      layerAndWorkspace.layer.name
    )

    const findRes = fields.find((el: any) => el.type === 'Length');
    const lengthName = findRes ? findRes.name : undefined;
    // console.log("selectedFeature :",selectedFeature);

    const properties = selectedFeature.properties;

    const keys = Object.keys(properties);
    let index = keys.indexOf('geometry');
    keys.splice(index, index > -1 ? 1 : 0);
    index = keys.indexOf('bbox');
    // keys.splice(index, index > -1 ? 1 : 0);
    // index = keys.indexOf('startpoint');
    // keys.splice(index, index > -1 ? 1 : 0);
    // index = keys.indexOf('middlepoint');
    // keys.splice(index, index > -1 ? 1 : 0);
    // index = keys.indexOf('endpoint');
    keys.splice(index, index > -1 ? 1 : 0);
    index = keys.indexOf(lengthName);
    keys.splice(index, index > -1 ? 1 : 0);
    interface LooseObject {
      [key: string]: string;
    }
    const copyproperties: LooseObject = {};
    for (const name of keys) {
      copyproperties[name] = properties[name];
    }
    const promises: Array<Promise<boolean>> = [];
    // setGeoJsonFeatureAr([])
    // setGeoJsonFinals([])
    const geoJsonFeatureAr = [];
    const geoJsonFinals = [];
    const oldCoords: number[][] = [];
    let arIndex = 0;
    for (let i = 0; i < split.features.length; i++) {
      const arCoords: number[][] = [];
      for (
        let j = 0;
        j < split.features[i].geometry.coordinates.length;
        j++
      ) {
        const xy = split.features[i].geometry.coordinates[j];
        const coordEl = coords.find((el: any) => el[0] === xy[0]);
        if (coordEl) {
          if (coordEl.length === 3) {
            arCoords.push([xy[0], xy[1], coordEl[2]]);
          } else {
            arCoords.push([xy[0], xy[1], -5000]);
          }
          arIndex++;
        } else {
          const before = coords[arIndex - 1];
          const next = coords[arIndex];
          const rate = (xy[0] - before[0]) / (next[0] - before[0]);
          if (next[2] && before[0]) {
            const returnZ = before[2] + rate * (next[2] - before[2]);
            arCoords.push([xy[0], xy[1], returnZ]);
          } else {
            arCoords.push([xy[0], xy[1], -5000]);
          }
        }
      }

      const panoPointsToLine = (points: Array<any>, isUpdate = false, oldFeature: any = null) => {
        const coordinates = [];
        for (const point of points) {
          coordinates.push(transform(point, 'EPSG:4326', 'EPSG:3857'));
        }
        let line: MultiLineString;
        if (isUpdate) {
          line = new MultiLineString([coordinates]);
          oldFeature!.setGeometry(line);
          return oldFeature;
        }
        line = new MultiLineString([coordinates]);
        const feature = new Feature({
          geometry: line,
        });
        return feature;
      }

      const featurize: any = {
        points: arCoords
      }
      // console.log("featurize :",featurize);

      // const digiGeom = panoGeomToOlGeom(featurize);


      const digiGeom = panoPointsToLine(arCoords).getGeometry()

      if (arCoords[0][2]) {
        await createEmptyForm(featurize);
        geomfields.forEach((geomfield: any) => {
          if (keys.includes(geomfield.name)) {
            copyproperties[geomfield.name] = geomfield.value;
          }
        });
      }

      const feature = new olFeature(copyproperties);

      feature.setGeometryName('geom');
      feature.setGeometry(digiGeom);
      const jsonReader = new GeoJSON();
      const geoJsonFeature = jsonReader.writeFeatureObject(feature);
      const options: IOptions = {
        operation: Operation.INSERT,
        typeName: layerAndWorkspace.layer.name,
        workspace: layerAndWorkspace.service.workspace,
        geometryName: 'geom',
        srsName: SrsName.EPSG_3857,
        srsDimension: SrsDimension.THREE_DIMENSION,
      };
      geoJsonFeatureAr.push(geoJsonFeature);

      if (lengthName) {
        // console.log(":lengthName: If")
        // console.log("geoJsonFeatureAr :", geoJsonFeatureAr);

        await calc(geoJsonFeatureAr[i], lengthName).then(
          (geoJsonFinal: any) => {

            promises.push(cutLines(geoJsonFinal, options));
          }
        );
      } else {
        // console.log(":promises: ELSE")
        // console.log("geoJsonFeatureAr :", geoJsonFeatureAr);


        promises.push(
          cutLines(geoJsonFeatureAr[i], options)
        );
      }
    }

    Promise.all(promises)
      .then(() => {

        //  const id = selectedFeature.id;
        let formatWFS: any = new WFS();
        let xs = new XMLSerializer();

        let node: any;
        const deleteFeature = new GeoJSON().readFeature(selectedFeature)
        //@ts-ignore
        node = formatWFS.writeTransaction(null, null, [deleteFeature], formatGML);

        let payload = xs.serializeToString(node)

        DigitizationRestApi.digiAddFeature(payload, layerAndWorkspace.service.url).then((res: any) => {

          if (res) {
            // dispatch(setToastMessageStatus(true))
            dispatch(setPanoramaTransactStatus(false))
          }
          // insert yapiliyorsa geoservera istek yapip featureId yi alacak o id ile attachment istegi yapacak

          const featureId = res.split('.')[1]
          if (typeof digiCallback.callbackFunction === 'function') {
            digiCallback.callbackFunction(featureId)
          }
          // console.log("DELETE GELDi");

          // FeatureRestApi.getFeatures(layerAndWorkspace.service.url, layerAndWorkspace.layer.name).then((res: any) => {
          //   if (res) {

              dispatch(setFeatures(res.data.features));

              // map update 
              const layersOfMap = map.getLayers();

              layersOfMap.forEach((layer: any) => {
                let source: any = layer.getSource();
                if (source.updateParams !== undefined)
                  source.updateParams({ 'time': Date.now() });
              });

              dispatch(setPanoramaTransactStatus(undefined))
              dispatch(setButtonState(ButtonState.NONE))
              dispatch(setSelectedFeature([]))

              map.updateSize();
              map.render();
          //   }
          // })


        })
      })
      .catch((err) => console.error('Error:' + err));

  }

  const getMiddlePointOfLine = (wkt: any): any => {
    const url = REACT_APP_BASE_URL + 'rest/v1/util/middlepoint'
    const body = {
      wkt: wkt
    }
    return new Promise((resolve, reject) => {

      axios.post(url, body).then((res: any) => {

        const obj = {
          middlePoint: res.data.middlePoint,
        }
        resolve(obj)
      },
        (error) => {
          reject(error)
        });
    })
  }

  const calculateMeasureResult: any = async (feature: any) => {
    if (!feature) {
      return;
    }
    const label: any = {
      location: undefined,
      locationX: undefined,
      locationY: undefined,
      locationZ: undefined,
      // length: undefined,
      perimeter: undefined,
      area: undefined,
      startPoint: undefined,
      endPoint: undefined,
      middlePoint: undefined,
      startPointX: undefined,
      endPointX: undefined,
      middlePointX: undefined,
      startPointY: undefined,
      endPointY: undefined,
      middlePointY: undefined,
      startPointZ: undefined,
      endPointZ: undefined,
      middlePointZ: undefined,
    };
    let geom;
    const wkt = new WKT();
    geom = new MultiLineString([feature.points]);
    const geomWktLine = wkt.writeGeometry(geom);
    geom.transform('EPSG:4326', 'EPSG:3857');
    // label.length = this.measurementResult
    label.startPoint =
      feature.points[0][0].toFixed(6) +
      ',' +
      feature.points[0][1].toFixed(6) +
      ',' +
      feature.points[0][2].toFixed(2);

    // console.log("label.startPoint : ",label.startPoint);

    label.startPointX = parseFloat(label.startPoint.split(',')[0]);
    label.startPointY = parseFloat(label.startPoint.split(',')[1]);
    label.startPointZ = parseFloat(label.startPoint.split(',')[2]);
    label.endPoint =
      feature.points[feature.points.length - 1][0].toFixed(6) +
      ',' +
      feature.points[feature.points.length - 1][1].toFixed(6) +
      ',' +
      feature.points[feature.points.length - 1][2].toFixed(2);
    label.endPointX = parseFloat(label.endPoint.split(',')[0]);
    label.endPointY = parseFloat(label.endPoint.split(',')[1]);
    label.endPointZ = parseFloat(label.endPoint.split(',')[2]);

    // console.log("geomWktLine : ",geomWktLine)
    const response: { middlePoint?: any } =
      await getMiddlePointOfLine(geomWktLine);
    if (response) {
      label.middlePoint = response.middlePoint;
      label.middlePointX = parseFloat(label.middlePoint.split(',')[0]);
      label.middlePointY = parseFloat(label.middlePoint.split(',')[1]);
      label.middlePointZ = parseFloat(label.middlePoint.split(',')[2]);
    }

    return new Promise((resolve) => {
      resolve(label);
    });
  }
  const createEmptyForm = async (feature: any) => {
    const result: {
      area?: any;
      perimeter?: any;
      length?: any;
      startPoint?: any;
      endPoint?: any;
      middlePoint?: any;
      location?: any;
      startPointX?: any;
      endPointX?: any;
      middlePointX?: any;
      locationX?: any;
      startPointY?: any;
      endPointY?: any;
      middlePointY?: any;
      locationY?: any;
      startPointZ?: any;
      endPointZ?: any;
      middlePointZ?: any;
      locationZ?: any;
    } = await calculateMeasureResult(
      feature /*this.feature*/ /*this.selectionService.selectedFeature*/
    );
    // this.layer = res;
    const layerInfo = layerAndWorkspace.layer;
    if (layerInfo) {
      const fieldsInfo = layerInfo.fields;
      if (fieldsInfo) {
        /* setAttachmentFieldName( fieldsInfo.find(
          (field:any) => field.type === 'Attachment'
        )); */
        for (const fieldInfo of fieldsInfo) {
          switch (fieldInfo.type) {
            case FiledTypes.LENGTH:
              fieldInfo.value = result.length;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;
            case FiledTypes.START_POINT:
              fieldInfo.value = result.startPoint;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;
            case FiledTypes.MIDDLE_POINT:
              fieldInfo.value = result.middlePoint;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;
            case FiledTypes.END_POINT:
              fieldInfo.value = result.endPoint;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;

            case FiledTypes.START_POINT_X:
              fieldInfo.value = result.startPointX;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;
            case FiledTypes.MIDDLE_POINT_X:
              fieldInfo.value = result.middlePointX;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;
            case FiledTypes.END_POINT_X:
              fieldInfo.value = result.endPointX;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;

            case FiledTypes.START_POINT_Y:
              fieldInfo.value = result.startPointY;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;
            case FiledTypes.MIDDLE_POINT_Y:
              fieldInfo.value = result.middlePointY;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;
            case FiledTypes.END_POINT_Y:
              fieldInfo.value = result.endPointY;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;

            case FiledTypes.START_POINT_Z:
              fieldInfo.value = result.startPointZ;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;
            case FiledTypes.MIDDLE_POINT_Z:
              fieldInfo.value = result.middlePointZ;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;
            case FiledTypes.END_POINT_Z:
              fieldInfo.value = result.endPointZ;
              fieldInfo.disabled = true;
              geomfields.push(fieldInfo);
              break;

            default:
              break;
          }
        }
        // geomfields = fieldsInfo;
        geomfields = geomfields.filter(
          (field: any) => field.name !== 'id'
        )
        // setGeomfields(geomfields.filter(
        //   (field:any) => field.name !== 'id'
        // ))
      }
    }
  }
  function serviceLayerKey(serviceName: string, layerName: string) {
    return serviceName + '-' + layerName
  }
  const getFieldsByService = (serviceName: string, layerName: string): any => {
    const key = serviceLayerKey(serviceName, layerName)
    if (serviceLayersMap.has(key)) {
      const layer: Layer = serviceLayersMap.get(key)!
      return layer.fields.map(field => Object.assign({}, field))
    }
    return []
  }
  const getLayerAndService = (featureId: string, services: any) => {
    let service;
    let layer;
    const layerName = featureId.split('.')[0]

    for (const item of services) {
      for (const testLayer of item.layers) {
        if (testLayer.name === layerName) {
          layer = testLayer
          service = item
        }
      }
    }
    const obj = {
      layer: layer,
      service: service
    }
    return obj
  }
  const onDrawEvent = (evt: any, type: DrawToolEventType): void => {
    if (type === DrawToolEventType.DRAWSTART) {
      // const layer = map.getLayers().getArray().find(
      //   (el: any) => el.getProperties().alias === 'boxDraw'
      // )
      // layer.getSource().clear()
      //  map.getSource().removeFeature(feature)
    } else if (DrawToolEventType.DRAWEND) {
      /*setDrawbbox*/
      // console.log("evt.feature.getGeometry() :",evt.feature.getGeometry());

      setLine(evt.feature.getGeometry())
      // setRemoveFeature(evt.feature)
    }
  }
  return (
    <>
      {line && <Dialog className='digi-popup' header='Çizgi Kesme' visible={line} onHide={() => { setLine(null); dispatch(setButtonState(ButtonState.NONE)) }}
        draggable={true} resizable={true} position='center'>
        <p>{t(`BUTTON.CutActionStart`)}</p><div className='buttons'>
          <Button label='İptal' onClick={() => { setLine(null); dispatch(setButtonState(ButtonState.NONE)) }} />
          <Button label='Kaydet' className='save-button' onClick={cutterDrawEvent} />
        </div>
      </Dialog>}
    </>
  )
}