import './ConfirmPopup.scss';
import { Dialog } from 'primereact/dialog'
import { useDispatch } from 'react-redux';
import { setCancelDigiActivate, setConfirmPopupVisibility, setFeatureAttachments, setMultipleDigiStatus, setPanoramaTransactStatus, setSelectedLayerFields, setTransactStatus } from '../../../../../state/features/digitizationSlice';
import { Button } from "primereact/button";
import { useAppSelector } from '../../../../../state/hooks';
import { GeoJSON } from 'ol/format';
import { DigitizationRestApi } from '../../../../../util/restapi/digitization';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useRef, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { AttachmentRestApi } from '../../../../../util/restapi/attachment';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import * as XmlBuilder from 'xmlbuilder';
import saveAs from 'file-saver';
import React from 'react';
import { setSelectedFeature } from '../../../../../state/features/selectedFeatureSlice';
import { setSelectedRow } from '../../../../../state/features/tableSlice';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import axios from 'axios';
import { ColorPicker } from 'primereact/colorpicker';
import { Calendar } from 'primereact/calendar';
import { MapContext } from '../../../map-container/map/MapContext';

interface Props {
    title: string,
    content: string,
    from: string,
    mode: string
}
export enum GeometryType {
    POINT = 'Point',
    LINESTRING = 'LineString',
    LINE = 'Line',
    POLYGON = 'Polygon',
    MULTIPOINT = 'MultiPoint',
    MULTILINESTRING = 'MultiLineString',
    MULTIPOLYGON = 'MultiPolygon',
}

export enum SrsDimension {
    TWO_DIMENSION = '2',
    THREE_DIMENSION = '3',
}

// Bu component "çoklu ve tekli feature property editleme" ile "çoklu ve tekli feature delete" işlemleri için oluşturulmuştur.
// Diğer işlemler (add, vertex, move, split vs.) DigiPopup.tsx componentindedir.
const ConfirmPopup = (props: Props) => {
    const toast = useRef<any>();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const map = useContext(MapContext);
    const [fields, setFields] = useState<any>([])
    const [formFields, setFormFields] = useState<any>(null)
    const [multipleEditField, setMultipleEditField] = useState<any>(null)
    const [attachment, setAttachment] = useState<any>([]);
    const [multipleEditAttachment, setMultipleEditAttachment] = useState<any>([]);
    const [attachmentExist, setAttachmentExist] = useState<any>(null);
    const [attachmentStatus, setAttachmentStatus] = useState<any>(null);
    let [selectedFeatureProperties, setSelectedFeatureProperties] = useState<any>(null)
    const [allUsers, setAllUsers] = useState<any>([]);
    const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature)
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)
    const workspace: any = useAppSelector(state => state.layer.workspace);
    const geoserverUrl: any = useAppSelector(state => state.layer.geoserverUrl);
    const featureAttachments: any = useAppSelector(state => state.digitization.featureAttachments);
    const { REACT_APP_BASE_URL } = process.env
    const token = localStorage.getItem('token')
    const cal = useRef(null);
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState);

    useEffect(() => {
        if (selectedLayer) {
            const filteredFields = selectedLayer.fields.filter((field: any) => field.name !== 'bbox' && field.name !== 'geom');
            const sortedFields = filteredFields.sort((a: any, b: any) => a.priority - b.priority);
            setFields(sortedFields);
            dispatch(setSelectedLayerFields(sortedFields))

            getUsers();
        }
    }, [selectedLayer])

    useEffect(() => {
        if (selectedFeature.length === 1 && selectedFeature[0].type) {
            setSelectedFeatureProperties(selectedFeature[0].properties);
            const id = (selectedFeature[0].id).split('.')[1];
            getAttachments(id)
            setAttachmentStatus(false)
        }
    }, [selectedFeature, attachmentStatus])

    useEffect(() => {
        if (featureAttachments.attachments && featureAttachments.attachments.length > 0) {
            for (const attach of featureAttachments.attachments) {
                const base64 = attach.split('base64,')[1]
                const imageBlob = dataURItoBlob(base64);
                const imageFile = new File([imageBlob], `${'screenshot'}.png`, { type: 'image/png' });
                // console.log(imageFile);
                setMultipleEditAttachment([...multipleEditAttachment, imageFile])
            }
        }
    }, [featureAttachments])

    useEffect(() => {
        if (multipleEditAttachment.length > 0) {
            setAttachment([...attachment, multipleEditAttachment])
        }
    }, [multipleEditAttachment])

    const getUsers = async () => {
        const usersResponse = await axios.get(REACT_APP_BASE_URL + `rest/v1/user?token=${token}`)
        setAllUsers(usersResponse.data.users)
    }

    const dataURItoBlob = (dataURI: any) => {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        return blob;
    }
    const getAttachments = (id: any) => {
        AttachmentRestApi.getAttachmentOfFeature(selectedLayer.id, id).then((res) => {
            const response = res.data
            if (response.length > 0) {
                const pattern = /[\/.](gif|jpg|jpeg|tiff|png|svg)$/;
                response.forEach((item: any) => {
                    const isValidImage = pattern.test(item.filename.toLowerCase());
                    const url2 = generateLink(item)
                    item.imageUrl = isValidImage ? url2 : null;
                    item.downloadUrl = url2 + `&download=true`;
                    item.isValidImage = isValidImage;
                });
                setAttachmentExist(response)
            }
            else {
                setAttachmentExist([])
            }
        })
    }

    const generateLink = (attachment: any) => {
        return REACT_APP_BASE_URL + `rest/v1/attachment/${attachment.id}?token=${token}`
    }

    const onChangeInput = (fieldId: string, fieldName: string, fieldValue: any, event: any, fieldType?: any) => {
        if (fieldName === 'color') {
            setSelectedFeatureProperties({
                ...selectedFeatureProperties,
                ['color']: `${'#' + fieldValue}`
            })
            setFormFields({ ...formFields, [fieldName]: `${'#' + fieldValue}` })
        } else {
            setSelectedFeatureProperties({
                ...selectedFeatureProperties,
                [fieldName]: fieldValue
            })
            setFormFields({ ...formFields, [fieldName]: fieldValue })
        }
    }

    const onChangeMultipleEdit = (event: any) => {
        setMultipleEditField(event.value)
    }

    const onHide = () => {
        dispatch(setConfirmPopupVisibility(false));
        dispatch(setCancelDigiActivate(true));
        dispatch(setButtonState(ButtonState.NONE));
    }

    const onSave = async (e: any) => {
        e.preventDefault();
        let xml;

        for (const feature of selectedFeature) {
            if (props.mode === 'DELETE') {
                xml = await deleteXmlGenerator(feature);
            }
            if (props.mode === 'UPDATE') {
                const options = {
                    typeName: selectedLayer.name,
                    workspace: workspace,
                    geometryName: 'geom',
                    srsName: 'EPSG:3857',
                    srsDimension: '3'
                }
                const editedFeature: any = new GeoJSON().readFeatures(feature)[0];
                editedFeature.setProperties(formFields);

                const geoJsonFeature = new GeoJSON().writeFeaturesObject([editedFeature]).features[0];
                xml = await updateXmlGenerator(options, geoJsonFeature);
            }

            if (attachment.length > 0) {
                for (const feature of selectedFeature) {
                    debugger
                    for (const file of attachment) {
                        const f = new FormData()
                        f.append('file', file)
                        AttachmentRestApi.addAttachment(selectedLayer.id, feature.id.split('.')[1], f).then((res: any) => {
                            if (res) {
                                setAttachment([])
                            }
                        }).catch((err: any) => { console.error(err); })
                    }
                }
            }
            await DigitizationRestApi.digiAddFeature(xml, geoserverUrl).then((res: any) => {
                if (res) {
                    // map update 
                    const layersOfMap = map.getLayers();

                    layersOfMap.forEach((layer: any) => {
                        let source: any = layer.getSource();
                        if (source.updateParams !== undefined)
                            source.updateParams({ 'time': Date.now() });
                    });
                    toast.current?.show({ severity: 'info', detail: (t('BUTTON.Operation Completed Successfully')), life: 5000 });
                    setTimeout(() => {
                        dispatch(setPanoramaTransactStatus(false))
                        dispatch(setTransactStatus(false))
                        dispatch(setConfirmPopupVisibility(false))
                        dispatch(setMultipleDigiStatus(false))
                        dispatch(setSelectedFeature([]))
                        dispatch(setSelectedRow(false))
                        dispatch(setButtonState(ButtonState.NONE))
                    }, 200);
                }
            })
        }
    }

    const deleteXmlGenerator = async (feature: any) => {
        const xml = XmlBuilder.create('Transaction', { encoding: 'utf-8' })
            .att({
                xmlns: 'http://www.opengis.net/wfs',
                service: 'WFS',
                version: '1.1.0',
                'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
                'xsi:schemaLocation': 'http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd'
            })
            .ele('Delete', { typeName: 'feature:' + selectedLayer.name, 'xmlns:feature': workspace })
            .ele('Filter', { xmlns: 'http://www.opengis.net/ogc' })
            .ele('FeatureId', { fid: feature.properties.id });

        return xml.end({ pretty: true });
    }

    const updateXmlGenerator = (options: any, geoJsonFeature: any) => {
        const geometryType = geoJsonFeature.geometry.type
        const geometryCoord = geoJsonFeature.geometry.coordinates

        const joinedCoordinates = joinedCoordinatesGenerator(geometryType, geometryCoord, options.srsDimension);

        const xml = XmlBuilder.create('Transaction', { encoding: 'utf-8' })
            .att({
                xmlns: 'http://www.opengis.net/wfs',
                service: 'WFS',
                version: '1.1.0',
                'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
                'xsi:schemaLocation': 'http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd'
            })
            .ele('Update', { typeName: 'feature:' + selectedLayer.name, 'xmlns:feature': workspace });
        const properties = geoJsonFeature.properties
        const featureId = geoJsonFeature.properties.id
        for (const property of Object.keys(properties)) {
            if (properties[property] !== undefined) {
                xml.ele('Property')
                    .ele('Name', property).up()
                    .ele('Value', properties[property]);
            }
        }

        if (buttonState !== ButtonState.EDIT) {
            switch (geometryType) {
                case GeometryType.POINT:
                    xml.ele('Property')
                        .ele('Name', {}, options.geometryName).up()
                        .ele('Value')
                        .ele(GeometryType.POINT, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('pos', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.LINESTRING:
                    xml.ele('Property')
                        .ele('Name', {}, options.geometryName).up()
                        .ele('Value')
                        .ele(GeometryType.MULTILINESTRING, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('lineStringMember')
                        .ele('LineString', { srsName: options.srsName })
                        .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.POLYGON:
                    xml.ele('Property')
                        .ele('Name', {}, options.geometryName).up()
                        .ele('Value')
                        .ele(GeometryType.MULTIPOLYGON, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('polygonMember')
                        .ele('Polygon', { srsName: options.srsName })
                        .ele('exterior')
                        .ele('LinearRing', { srsName: options.srsName })
                        .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.MULTIPOINT:
                    xml.ele('Property')
                        .ele('Name', {}, options.geometryName).up()
                        .ele('Value')
                        .ele(GeometryType.MULTIPOINT, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('pointMember')
                        .ele('Point', { srsName: options.srsName })
                        .ele('pos', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.MULTILINESTRING:
                    xml.ele('Property')
                        .ele('Name', {}, options.geometryName).up()
                        .ele('Value')
                        .ele(GeometryType.MULTILINESTRING, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('lineStringMember')
                        .ele('LineString', { srsName: options.srsName })
                        .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.LINE:
                    xml.ele('Property')
                        .ele('Name', {}, options.geometryName).up()
                        .ele('Value')
                        .ele(GeometryType.MULTILINESTRING, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('lineStringMember')
                        .ele('LineString', { srsName: options.srsName })
                        .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.MULTIPOLYGON:
                    xml.ele('Property')
                        .ele('Name', {}, options.geometryName).up()
                        .ele('Value')
                        .ele(GeometryType.MULTIPOLYGON, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('polygonMember')
                        .ele('Polygon', { srsName: options.srsName })
                        .ele('exterior')
                        .ele('LinearRing', { srsName: options.srsName })
                        .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
            }
        }
        xml.ele('Filter', { xmlns: 'http://www.opengis.net/ogc' })
            .ele('FeatureId', { fid: featureId });

        return xml.end({ pretty: true });
    }

    const joinedCoordinatesGenerator = (geometryType: any, geometryCoord: any, srsDimension: SrsDimension) => {
        const coordinates = [];
        switch (geometryType) {
            case GeometryType.POINT:
                if (srsDimension === SrsDimension.TWO_DIMENSION) {
                    coordinates.push(geometryCoord[0] + ' ' + geometryCoord[1]);
                }
                else {

                    if (geometryCoord.length === 2) {
                        coordinates.push(geometryCoord.join(' ') + ' ' + 0);
                    } else {
                        coordinates.push(geometryCoord.join(' '));
                    }
                }

                break;
            case GeometryType.MULTIPOINT:
            case GeometryType.LINESTRING:
                if (srsDimension === SrsDimension.TWO_DIMENSION) {
                    for (const point of geometryCoord) {
                        coordinates.push(point[0] + ' ' + point[1]);
                    }
                } else {
                    for (const point of geometryCoord) {
                        if (point.length === 2) {
                            coordinates.push(point.join(' ') + ' ' + 0);
                        } else {
                            coordinates.push(point.join(' '));
                        }
                    }
                }
                break;
            case GeometryType.LINE:
                if (srsDimension === SrsDimension.TWO_DIMENSION) {
                    for (const point of geometryCoord) {
                        coordinates.push(point[0] + ' ' + point[1]);
                    }
                } else {
                    for (const point of geometryCoord) {
                        if (point.length === 2) {
                            coordinates.push(point.join(' ') + ' ' + 0);
                        } else {
                            coordinates.push(point.join(' '));
                        }
                    }
                }
                break;
            case GeometryType.MULTILINESTRING:
            case GeometryType.POLYGON:
                if (srsDimension === SrsDimension.TWO_DIMENSION) {
                    for (const line of geometryCoord) {
                        for (const point of line) {
                            coordinates.push(point[0] + ' ' + point[1]);
                        }
                    }
                } else {
                    for (const line of geometryCoord) {
                        for (const point of line) {
                            if (point.length === 2) {
                                coordinates.push(point.join(' ') + ' ' + 0);
                            } else {
                                coordinates.push(point.join(' '));
                            }
                        }
                    }
                }
                break;
            case GeometryType.MULTIPOLYGON:
                if (srsDimension === SrsDimension.TWO_DIMENSION) {
                    for (const polygon of geometryCoord) {
                        for (const line of polygon) {
                            for (const point of line) {
                                coordinates.push(point[0] + ' ' + point[1]);
                            }
                        }
                    }
                } else {
                    for (const polygon of geometryCoord) {
                        for (const line of polygon) {
                            for (const point of line) {
                                if (point.length === 2) {
                                    coordinates.push(point.join(' ') + ' ' + 0);
                                } else {
                                    coordinates.push(point.join(' '));
                                }
                            }
                        }
                    }
                }
                break;
        }

        return coordinates.join(' ');
    }

    const onFileSelect = (e: any, field: any) => {
        let _totalSize = field.fileSize ? field.fileSize : 0;
        const files = [...e.files]

        files.forEach((file: any) => {
            _totalSize += file.size;
        });

        setAttachment([...attachment, files[0]])

        let findedAttFieldIndex = fields.findIndex((attField: any) => attField.id === field.id)
        const copyFields = [...fields]
        copyFields[findedAttFieldIndex] = {
            ...copyFields[findedAttFieldIndex],
            files: files,
            fileSize: _totalSize
        }
    }

    const downloadFile = (item: any) => {
        saveAs(item.downloadUrl);
    }

    const deleteFile = (item: any) => {
        confirmDialog({
            message: 'Dosyayı Silmek İstediğinize Emin Misiniz?',
            header: 'Dosyayı Sil',
            icon: 'pi pi-exclamation-triangle',
            className: 'attach-confirm-dialog',
            accept: async () => {
                try {
                    await axios.delete(REACT_APP_BASE_URL + `rest/v1/attachment/${item.id}`, { params: { token: token } });
                    setAttachmentStatus(true)
                } catch (error) {
                    console.error(error);
                }
            },
            reject: () => { setAttachmentStatus(false) },
            acceptLabel: `${t('BUTTON.Save')}`,
            rejectLabel: `${t('BUTTON.Cancel')}`
        });
    }

    const headerTemplate = (options: any) => {
        options.props.chooseOptions.label = `${t('BUTTON.Select File')}`
        options.props.chooseOptions.icon = 'none'
        const { chooseButton } = options;

        return (
            <>
                {chooseButton}
            </>
        );
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <span style={{ padding: '10px', 'fontSize': '1em', color: 'var(--text-color-secondary)' }} className="my-5"></span>
            </div>
        )
    }

    const itemTemplate = (file: any, props: any) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="item-group">
                    {file.objectURL ?
                        <Image alt={file.name} preview src={file.objectURL} /> :
                        <div className='image-box'>
                            <i className="pi pi-file" style={{ 'fontSize': '2em', color: '#863564' }}></i>
                        </div>
                    }
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                    </span>
                    <Tag value={props.formatSize} className="px-3 py-2" />
                    <Button type="button" icon="pi pi-times" className="p-button-sm p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(props.onRemove)} />
                </div>
            </div>
        )
    }

    const onTemplateRemove = (callback: any) => {
        callback();
    }

    return (
        <>
            {/*tekli ve çoklu feature delete işlemi için */}
            {props.mode === 'DELETE' && <Dialog header={props.title} visible={true} onHide={onHide}>
                <p>{props.content}</p>
                <div className='buttons'>
                    <Button label={buttonState === ButtonState.DELETE ? 'Hayır' : 'İptal'} onClick={onHide}></Button>
                    <Button label={buttonState === ButtonState.DELETE ? 'Evet' : 'Kaydet'} className='save-button' onClick={onSave}></Button>
                </div>
            </Dialog>}

            {/* tek feature property editlenirken */}
            {props.mode === 'UPDATE' && selectedFeature.length === 1 &&
                <Dialog header={props.title} visible={true} onHide={onHide}>

                    <form className='form' onSubmit={onSave}>
                        {fields.map((field: any, index: any) => <React.Fragment key={field.id}>
                            {(field.type === 'Text' || field.type === 'Telephone') &&
                                <>
                                    {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                                    <InputText name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}
                            {field.type === 'CreatedBy' && selectedFeatureProperties &&
                                <>
                                    {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                                    <InputText disabled name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties ? allUsers.find((obj: any) => obj.id === selectedFeatureProperties[field.name])?.username : field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}
                            {field.type === 'TouchBy' && selectedFeatureProperties &&
                                <>
                                    {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                                    <InputText disabled name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties ? allUsers.find((obj: any) => obj.id === selectedFeatureProperties[field.name])?.username : field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}
                            {field.type === 'CreatedDate' && selectedFeatureProperties &&
                                <>
                                    {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                                    <Calendar
                                        locale="tr"
                                        className={`.p-calendar .p-inputtext input-style`}
                                        name={field.name}
                                        value={new Date(selectedFeatureProperties[field.name])}
                                        disabled
                                        dateFormat="dd-mm-yy"
                                        readOnlyInput
                                        placeholder='Başlangıç Tarihi Seçiniz'
                                        ref={cal}
                                        id={field.alias}
                                        required={!field.nullable ? !selectedFeatureProperties[field.name] : false}
                                    >
                                    </Calendar>
                                    {/* <InputText disabled name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' /> */}
                                </>}
                            {field.type === 'TouchDate' && selectedFeatureProperties &&
                                <>
                                    {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                                    <Calendar
                                        locale="tr"
                                        className={`.p-calendar .p-inputtext input-style`}
                                        name={field.name}
                                        value={new Date(selectedFeatureProperties[field.name])}
                                        disabled
                                        dateFormat="dd-mm-yy"
                                        readOnlyInput
                                        placeholder='Başlangıç Tarihi Seçiniz'
                                        ref={cal}
                                        id={field.alias}
                                        required={!field.nullable ? !selectedFeatureProperties[field.name] : false}
                                    >
                                    </Calendar>
                                    {/* <InputText disabled name={field.name} key={field.id} id={field.alias} value={new Date(selectedFeatureProperties ? selectedFeatureProperties[field.name] : field.inputValue)} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' /> */}
                                </>}
                            {field.type === 'Id' && selectedFeatureProperties &&
                                <>
                                    {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                                    <InputText disabled name={field.name} key={field.id} id={field.alias} value={selectedFeatureProperties[field.name]} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}
                            {field && (field.type === 'Float') &&
                                <>
                                    {!field.nullable ? <label htmlFor='minmaxfraction'>{field.alias} *</label> : <label htmlFor='minmaxfraction'>{field.alias}</label>}
                                    <InputNumber name={field.name} inputId='minmaxfraction' mode='decimal' minFractionDigits={1} maxFractionDigits={6} id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='input-style' />
                                </>}
                            {field && (field.type === 'Integer') &&
                                <>
                                    {!field.nullable ? <label htmlFor='int'>{field.alias} *</label> : <label htmlFor='int'>{field.alias}</label>}
                                    <InputNumber name={field.name} inputId='int' mode='decimal' id={field.alias} value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='input-style' />
                                </>}
                            {field && field.type === 'Domain' &&
                                <>
                                    {!field.nullable ? <label htmlFor='domain'>{field.alias} *</label> : <label htmlFor='domain'>{field.alias}</label>}
                                    <Dropdown name={field.name} className='input-style' value={selectedFeatureProperties[field.name]} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} options={field.domain} optionLabel='desc' optionValue='id' onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                </>}

                            {field && (field.type === 'Color') &&
                                <>
                                    {!field.nullable ? <label htmlFor='color'>{field.alias} *</label> : <label htmlFor='color'>{field.alias}</label>}

                                    <ColorPicker format="hex" name={field.name} inputId='color' id={field.alias}
                                        value={selectedFeatureProperties[field.name]}
                                        required={!field.nullable ? !selectedFeatureProperties[field.name] : false} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='input-style' />

                                </>}

                            {field && (field.type === 'Date') &&
                                <>
                                    {!field.nullable ? <label htmlFor='date'>{field.alias} *</label> : <label htmlFor='date'>{field.alias}</label>}

                                    <Calendar
                                        locale="tr"
                                        className={`.p-calendar .p-inputtext input-style`}
                                        name={field.name}
                                        value={new Date(selectedFeatureProperties[field.name])}
                                        onChange={(e: any) => {
                                            onChangeInput(field.id, field.name,
                                                new Date(e.value).getFullYear() + '-' +
                                                ('0' + (new Date(e.value).getMonth() + 1)).slice(-2) + '-' +
                                                ('0' + new Date(e.value).getDate()).slice(-2), e)
                                        }
                                        }

                                        dateFormat="dd-mm-yy"
                                        readOnlyInput
                                        placeholder='Başlangıç Tarihi Seçiniz'
                                        ref={cal}
                                        id={field.alias}
                                        required={!field.nullable ? !selectedFeatureProperties[field.name] : false}
                                    >
                                    </Calendar>

                                </>}

                            {field && field.type === 'Attachment' &&
                                <>
                                    {!field.nullable ? <label htmlFor='domain'>{field.alias} *</label> : <label htmlFor='domain'>{field.alias}</label>}
                                    <FileUpload name="demo" headerTemplate={headerTemplate} emptyTemplate={emptyTemplate} itemTemplate={itemTemplate} accept="/*" maxFileSize={5000000} onSelect={(e) => onFileSelect(e, field)} className='input-style' />
                                    {attachmentExist && attachmentExist.length > 0 && attachmentExist.map((file: any) => <div className='attach'>
                                        <div><Image preview src={file.imageUrl ? file.imageUrl : 'customize-icons/file.svg'} alt="" /></div>
                                        <div><p>{file.filename}</p></div>
                                        <div className='button-container'>
                                            <a className='anim anim-from-left' onClick={() => downloadFile(file)} download>
                                                {t('BUTTON.Download')}
                                            </a>
                                            <a className='anim anim-from-left' onClick={() => deleteFile(file)}>
                                                Sil
                                            </a>
                                        </div>
                                    </div>

                                    )}
                                </>}


                        </React.Fragment>
                        )}

                        <div className='buttons'>
                            <Button type={'button'} label='İptal' onClick={onHide}></Button>
                            <Button label='Kaydet' type="submit" className='save-button'></Button>
                        </div>
                    </form>
                </Dialog>}

            {/* birden fazla feature property editlerken */}
            {props.mode === 'UPDATE' && selectedFeature.length > 1 &&
                <Dialog header={props.title} visible={true} onHide={onHide}>
                    <form className='form' onSubmit={onSave}>
                        <Dropdown
                            className='input-style'
                            name={multipleEditField}
                            value={multipleEditField}
                            options={fields.filter((field: any) => field.type !== 'CreatedBy' && field.type !== 'CreatedDate' && field.type !== 'TouchBy' && field.type !== 'TouchDate' && field.type !== 'Id' && field.type !== 'Location' && field.type !== 'Middle_Point')}
                            placeholder={t('BUTTON.Select Attribute')}
                            optionLabel='alias'
                            optionValue='name'
                            onChange={(e: any) => onChangeMultipleEdit(e)}
                            style={{ height: '36px', display: 'flex', alignItems: 'center', marginBottom: '40px' }} />

                        {fields.map((field: any, index: any) => <>
                            {field.name === multipleEditField && (field.type === 'Text' || field.type === 'Telephone') &&
                                <>
                                    {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                                    <InputText name={field.name} key={field.id} id={field.alias} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} maxLength={250} className='input-style' />
                                </>}
                            {field.name === multipleEditField && field && (field.type === 'Float') &&
                                <>
                                    {!field.nullable ? <label htmlFor='minmaxfraction'>{field.alias} *</label> : <label htmlFor='minmaxfraction'>{field.alias}</label>}
                                    <InputNumber name={field.name} inputId='minmaxfraction' mode='decimal' minFractionDigits={1} maxFractionDigits={6} id={field.alias} value={field.inputValue} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='input-style' />
                                </>}
                            {field.name === multipleEditField && field && (field.type === 'Integer') &&
                                <>
                                    {!field.nullable ? <label htmlFor='int'>{field.alias} *</label> : <label htmlFor='int'>{field.alias}</label>}
                                    <InputNumber name={field.name} inputId='int' mode='decimal' id={field.alias} value={field.inputValue} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='input-style' />
                                </>}
                            {field.name === multipleEditField && field && field.type === 'Domain' &&
                                <>
                                    {!field.nullable ? <label htmlFor='domain'>{field.alias} *</label> : <label htmlFor='domain'>{field.alias}</label>}
                                    <Dropdown name={field.name} className='input-style' value={field.inputValue} required={!field.nullable ? !selectedFeatureProperties[field.name] : false} options={field.domain} optionLabel='desc' optionValue='id' onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                </>}
                            {field.name === multipleEditField && field && field.type === 'Color' &&
                                <>
                                    {!field.nullable ? <label htmlFor='color'>{field.alias} *</label> : <label htmlFor='color'>{field.alias}</label>}

                                    <ColorPicker format="hex" name={field.name} inputId='color' id={field.alias}
                                        value={selectedFeatureProperties[field.name]}
                                        required={!field.nullable ? !selectedFeatureProperties[field.name] : false} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='input-style' />

                                </>}
                            {field.name === multipleEditField && field && (field.type === 'Date') &&
                                <>
                                    {console.log('vbknjml', multipleEditField, field)}
                                    {!field.nullable ? <label htmlFor='date'>{field.alias} *</label> : <label htmlFor='date'>{field.alias}</label>}
                                    <Calendar
                                        locale="tr"
                                        className={`.p-calendar .p-inputtext input-style`}
                                        name={field.name}
                                        value={new Date(selectedFeatureProperties[field.name])}
                                        onChange={(e: any) => {
                                            onChangeInput(field.id, field.name,
                                                new Date(e.value).getFullYear() + '-' +
                                                ('0' + (new Date(e.value).getMonth() + 1)).slice(-2) + '-' +
                                                ('0' + new Date(e.value).getDate()).slice(-2), e)
                                        }
                                        }

                                        dateFormat="dd-mm-yy"
                                        readOnlyInput
                                        placeholder='Başlangıç Tarihi Seçiniz'
                                        ref={cal}
                                        id={field.alias}
                                        required={!field.nullable ? !selectedFeatureProperties[field.name] : false}
                                    >
                                    </Calendar>

                                </>}
                            {/* {field.alias === multipleEditField && field && field.type === 'Attachment' &&
                                <>
                                    {!field.nullable ? <label htmlFor='domain'>{field.alias} *</label> : <label htmlFor='domain'>{field.alias}</label>}
                                    <FileUpload name="demo" headerTemplate={headerTemplate} emptyTemplate={emptyTemplate} itemTemplate={itemTemplate} accept="/*" maxFileSize={5000000} onSelect={(e) => onFileSelect(e, field)} className='input-style' />
                                </>} */}
                            {field.name === multipleEditField && field && field.type === 'Attachment' &&

                                <>
                                    {!field.nullable ? <label htmlFor='domain'>{field.alias} *</label> : <label htmlFor='domain'>{field.alias}</label>}
                                    <FileUpload name="demo" headerTemplate={headerTemplate} emptyTemplate={emptyTemplate} itemTemplate={itemTemplate} accept="/*" maxFileSize={5000000} onSelect={(e) => onFileSelect(e, field)} className='input-style' />
                                    {featureAttachments && featureAttachments.attachments.length > 0 && featureAttachments.attachments.map((attach: any, i: any) =>
                                        <div className='attach'>
                                            <>
                                                <img className='digi-screenshot-img' src={attach ? attach : 'customize-icons/file.svg'} alt='' style={{ maxWidth: '900px' }} />
                                                <div className='button-container'>
                                                    <a className='anim anim-from-left' onClick={() => deleteFile(attach)}>
                                                        Sil
                                                    </a>
                                                </div>
                                            </>
                                        </div>
                                    )
                                    }
                                </>}
                        </>
                        )}
                        <div className='buttons'>
                            <Button type={'button'} label='İptal' onClick={onHide}></Button>
                            <Button label='Kaydet' type="submit" className='save-button' disabled={!multipleEditField}></Button>
                        </div>
                    </form>
                </Dialog >
            }
            <ConfirmDialog />
            <Toast ref={toast} position='bottom-center' className='login-toast' style={{ zIndex: '9 !important' }} />
        </>
    )
}

export default ConfirmPopup