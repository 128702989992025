import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { ButtonState } from '../shared/button-state/ButtonState';

export const Help = () => {
    const dispatch = useAppDispatch();
    const [activeIndex, setActiveIndex] = useState<number>(-1);
    const buttonState = useAppSelector(state => state.buttonState.buttonState)

    return (
        <>
            <Dialog header="Yardım" visible={buttonState === ButtonState.HELP} style={{ width: '675px' }} onHide={() => dispatch(setButtonState(ButtonState.NONE))}>
                <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <AccordionTab header="Uygulama Giriş-Çıkış">İnternet tarayıcısının adres bölümüne uygulama adresi girilerek uygulama açılır. Uygulama açıldığında e-devlet kullanıcı bilgileri ile giriş yapılır.
                        <img className="contain" src="/help/giris-cikis/giris-help.png" alt="Bulunamadı" />
                        Uygulamanın sağ üst çubuğunda bulunan “Çıkış” butonuna basılarak uygulamadan çıkış yapılır.
                        <img className="contain" src="/help/giris-cikis/cikis-help.png" alt="Bulunamadı" />
                    </AccordionTab>
                    <AccordionTab header="Katmanlar">
                        Katmanlar paneli, üst çubuğun solunda bulunan   “ <img src="/help/katmanlar/katmanlar-button-passive.png" alt="Bulunamadı" />  “ simgeden açılır.
                        <img className="contain" src="/help/katmanlar/katmanlar1.png" alt="Bulunamadı" />
                        Uygulamanın sol üstünde görünen “ <img src="/help/katmanlar/katmanlar-button-active.png" alt="Bulunamadı" />  “ simge tıklanarak katmanlar kapatılır.
                        <img className="contain" src="/help/katmanlar/katmanlar2.png" alt="Bulunamadı" />
                        Katman panelinde bulunan katman grupları içerisindeki katmanları görüntülemek için, grup adının yanındaki “ <img src="/help/katmanlar/katmanlar-arrow-right.png" alt="Bulunamadı" />  “ simge tıklanarak genişletilir. Böylece katman grubunda bulunan katmanlar listelenir.
                        <img className="contain" src="/help/katmanlar/katmanlar3.png" alt="Bulunamadı" />
                        Katman panelinde bulunan katman grupları içerisindeki katmanları görüntülemek için, grup adının yanındaki “ <img src="/help/katmanlar/katmanlar-arrow-down.png" alt="Bulunamadı" />  “ simge tıklanarak genişletilir. Böylece katman grubunda bulunan katmanlar listelenir.
                        <img className="contain" src="/help/katmanlar/katmanlar4.png" alt="Bulunamadı" />
                        Katmanların isimlerinin yanındaki “ <img src="/help/katmanlar/katmanlar-checkbox.png" alt="Bulunamadı" />  “ yer alan kutu katmanların harita ekranındaki görünürlüğü ayarlanır. Kutu tıklanarak sarı renge çevrildiğinde o katman görünür olur. En üstte bulunan katman grubunun yanındaki kutudan ise katman grubuna dahil olan tüm katmanların görünürlüğü açılıp kapatılabilir.
                        <img className="contain" src="/help/katmanlar/katmanlar5.png" alt="Bulunamadı" />
                        Katmanlar panelinde seçilen katman farenin sağ tuşu ile tıklandığında açılan pencereden;
                        <ul>
                            <li>Tablo Aç</li>
                            <li>Dışa Aktar</li>
                        </ul>
                        seçilebilir.
                        <img className="contain" src="/help/katmanlar/katmanlar6.png" alt="Bulunamadı" />
                        Katmanlar panelinde seçilen katman farenin sağ tuşu ile tıklandığında açılan pencereden “Tablo Aç” seçilerek ilgili katmanın öznitelik bilgilerinin bulunduğu veri tablosu açılır/kapanır.
                        <img className="contain" src="/help/katmanlar/katmanlar7.png" alt="Bulunamadı" />
                        Katman panelinde açılan tabloda alan adların bulunan “ <img src="/help/katmanlar/katmanlar-filter-button.png" alt="Bulunamadı" />  “ sembole tıklanır. Açılan kutu içerisinde tabloda sütunda yer alan veriler listelenir.
                        <img className="contain" src="/help/katmanlar/katmanlar8.png" alt="Bulunamadı" />
                        Listelenilen verilerin sol tarafında yer alan kutu tıklanarak veri seçilir. Seçilen veri “ Uygula” tıklanarak filtrelenen veri listelenir.
                        <img className="contain" src="/help/katmanlar/katmanlar9.png" alt="Bulunamadı" />
                        Tabloda yer alan veri seçilerek “Seçili Veriye Git” butonu tıklanır. Harita ekranı üzerinde seçili verinin geometrisi ve bilgisi getirilir.
                        <img className="contain" src="/help/katmanlar/katmanlar10.png" alt="Bulunamadı" />
                        Tabloda seçilen veriye git seçeneği seçilerek gösterilen verinin geometrisi ve bilgisi “Seçimi Temizle” butonu tıklanarak açık veriler kapatılır.
                        <img className="contain" src="/help/katmanlar/katmanlar11.png" alt="Bulunamadı" />
                        Tabloda yer alan veriler “CSV Dışa Aktar” butonundan dışa aktarılır. Butona tıklandığında dışa aktarılan tablo. csv formatında indirilir.
                        <img className="contain" src="/help/katmanlar/katmanlar12.png" alt="Bulunamadı" />
                        Katmanlar panelinde seçilen katman farenin sağ tuşu ile tıklandığında açılan pencereden “Dışa Aktar” seçilerek ilgili katmanın dışarı alınmasını sağlar.
                        <img className="contain" src="/help/katmanlar/katmanlar13.png" alt="Bulunamadı" />
                        Dışa aktar tuşuna basıldıktan sonra istenilen formatta(Kml, ShapeFile) koordinat sistemi seçilerek export alınabilmektedir.
                        <img className="contain" src="/help/katmanlar/katmanlar14.png" alt="Bulunamadı" />
                        Katman panelinde katman aktif hale getirilerek harita ekranı üzerinde yer alan katman sembolojisinin şeffaflığını ayarlanabilmektedir.
                        <img className="contain" src="/help/katmanlar/katmanlar15.png" alt="Bulunamadı" />
                        Aktifleştirilen katmanın harita ekranı üzerinde katmanın geometrisine tıklandığında o katmana ait isim açılır. Üst ölçekte yer bilgi alındığında seçilen geometriye yakın geometri listesi açılır.
                        <img className="contain" src="/help/katmanlar/katmanlar16.png" alt="Bulunamadı" />
                        Açılan veri listesinde seçim yapılarak bilgi penceresi açılır.
                        <img className="contain" src="/help/katmanlar/katmanlar17.png" alt="Bulunamadı" />
                        Katman panelinde “Parsel” katmanı aktifleştirilir. Harita ekranında sağ alt köşede lejant açılır. Lejant yönetiminde “Tahsis Durumu” ve “Parsel Niteliği” seçenekleri seçildiğinde harita ekranı üzerinde geometri renkleri değişmektedir.
                        <img className="contain" src="/help/katmanlar/katmanlar18.png" alt="Bulunamadı" />
                        Katman panelinde “Parsel” katmanı aktifleştirilir. Harita ekranında parsel bilgisi alınır. Açılan bilgi kartında yer alan “Ruhsat Bilgileri” sekmesinde “Ruhsat Ekle” butonuna tıklanır. Açılan pencereden “Ruhsat Tipi” ve “Ruhsat Ekleme Tipi” seçenekleri yer alır. Ruhsat eklem tipi. “Manuel” ve “Bülten No”  olmak üzere iki farklı şekilde sorgular. Seçimler yapılarak “Ruhsat Sorgulama” butonu tıklanarak yeni pencere açılır. Sorgulanan ruhsat “Ruhsat Ekle” butonu tıklanarak eklenir.
                        <img className="contain" src="/help/katmanlar/katmanlar19.png" alt="Bulunamadı" />
                        Katman panelinde “Parsel” katmanı aktifleştirilir. Harita ekranında parsel bilgisi alınır. Açılan bilgi kartında yer alan “Yatırımcı Bilgileri” sekmesinde “Yatırımcı Ekle” butonuna tıklanır. Açılan pencereden veri girişi yapılarak “Kaydet” butonuna tıklanır.
                        <img className="contain" src="/help/katmanlar/katmanlar20.png" alt="Bulunamadı" />
                        Harita ekranında aktif katman bilgisi alınır. Açılan bilgi kartında sol alt köşede yer alan “Düzenle” butonu tıklanır.
                        <img className="contain" src="/help/katmanlar/katmanlar21.png" alt="Bulunamadı" />
                        Bilgi alınan verinler düzenlenerek “Kaydet” butonu tıklanarak veriler kaydedilir.
                        <img className="contain" src="/help/katmanlar/katmanlar22.png" alt="Bulunamadı" />
                    </AccordionTab>

                    <AccordionTab header="Harita Altlıkları">
                        Harita ekranının sol-alt kısmından harita altlıkları değiştirilebilir. Uygulamada Google Street, Google Hybrid, Google Satellite ve Open Street Map altlıkları bulunmaktadır.
                        <img className="contain" src="/help/altliklar/altliklar.png" alt="Bulunamadı" />
                    </AccordionTab>

                    <AccordionTab header="Yazdir">
                        Uygulamanın sağ alt bölümünde yer alan “ <img src="/help/print/yazdir-button.png" alt="Bulunamadı" />  “ sembole tıklanarak harita ekranında yer alan veriler indirilir.
                        <img className="contain" src="/help/print/yazdir.png" alt="Bulunamadı" />
                    </AccordionTab>

                    {/* <AccordionTab header="Konuma Git">
                        Uygulamanın sağ alt bölümünde yer alan “ <img src="/help/gotolocation/gotolocation-button.png" alt="Bulunamadı" />  “ sembole tıklanarak harita ekranında açılan ekrandan konum verisi girilir.  Konum verisi girildikten sonra “Git” butonu tıklanarak konuma gidilir.
                        <img className="contain" src="/help/gotolocation/gotolocation.png" alt="Bulunamadı" />
                    </AccordionTab> */}

                    {/* <AccordionTab header="Ölçek Ayarlama">
                        Uygulamanın sağ alt bölümünde yer alan ölçeğe tıklanarak ölçek listesi açılır. Listeden seçilen ölçek harita ekranında açılır.
                        <img className="contain" src="/help/olcekayarlama/olcekayarlama.png" alt="Bulunamadı" />
                    </AccordionTab> */}

                    {/* <AccordionTab header="E-Tahsis">
                        Uygulamanın sağ üst bölümünde yer alan “ <img src="/help/tahsis/tahsis-button.png" alt="Bulunamadı" />  “ sembole tıklanarak tahsis işlemlerinin yapıldığı ekran açılır.
                        <img className="contain" src="/help/tahsis/e-tahsis1.png" alt="Bulunamadı" />
                        Tahsis işlemleri için katmanlardan parsel katmanı aktifleştirilir. Harita ekranında boş parsel seçilir.
                        Aktifleştirilen parsel seçilerek “Evet” tıklanarak Tahsis İşlemlerinin yapıldığı ekran açılır. Parsel seçimi “Hayır” seçeneği ile kaldırılır.
                        <img className="contain" src="/help/tahsis/e-tahsis2.png" alt="Bulunamadı" />
                        Parsel seçiminde “Evet” seçeneği seçilerek Tahsis işlemleri açılır.
                        <img className="contain" src="/help/tahsis/e-tahsis3.png" alt="Bulunamadı" />
                    </AccordionTab> */}

                    {/* <AccordionTab header="Panel">
                        Uygulamanın sağ üst bölümünde yer alan “ <img src="/help/panel/panel-button.png" alt="Bulunamadı" />  “ sembole tıklanarak panel yönetiminin yapıldığı ekran açılır.
                        <img className="contain" src="/help/panel/panel1.png" alt="Bulunamadı" />
                        <img className="contain" src="/help/panel/panel2.png" alt="Bulunamadı" />
                    </AccordionTab> */}

                    {/* <AccordionTab header="Başvurular">
                        Uygulamanın sağ üst bölümünde yer alan “ <img src="/help/panel/panel-button.png" alt="Bulunamadı" />  “ sembole tıklanarak başvuruların yapıldığı ekran açılır.
                        <img className="contain" src="/help/basvuru/basvuru1.png" alt="Bulunamadı" />
                        <img className="contain" src="/help/basvuru/basvuru2.png" alt="Bulunamadı" />
                    </AccordionTab> */}

                    {/* <AccordionTab header="KML Yükleme">
                        Uygulamanın sağ üst bölümünde yer alan “ <img src="/help/kml/kml-button.png" alt="Bulunamadı" />  “ sembole tıklanarak açılır. Açılan pencerede kml formatında dosya “+” sembolüne tıklanarak seçilir. Seçilen dosya “İçe Aktar” butonu tıklanarak harita ekranında açılır. İçe aktarılan kml dosyası katman panelinde geçici katman olarak gösterilir. Uygulama yenilendiğinde geçici katman silinir.
                        <img className="contain" src="/help/kml/kml1.png" alt="Bulunamadı" />
                        <img className="contain" src="/help/kml/kml2.png" alt="Bulunamadı" />
                        <img className="contain" src="/help/kml/kml3.png" alt="Bulunamadı" />
                    </AccordionTab> */}

                    {/* <AccordionTab header="TKGM Sorgulama">
                        Uygulamada TKGM sorgulama iki yöntemle yapılır. Birinci yöntem uygulamanı sağ üst bölümünde yer alan “ <img src="/help/tkgm/tkgm-button.png" alt="Bulunamadı" />  “ sembole tıklanarak açılır. İkinci yöntem farenin sağ tuşuna basıldığında yer alan “Parsel Sorgula” seçeneğinden yapılır.
                        <img className="contain" src="/help/tkgm/tkgm1.png" alt="Bulunamadı" />
                        TKGM sorgulama için birinci yöntemde “ <img src="/help/tkgm/tkgm-button.png" alt="Bulunamadı" />  “ sembole tıklanarak pencere açılır. Açıla pencere sorgulanacak veriler girişi yapılır ve “Sorgula” butonuna tıklanarak açılır.
                        <img className="contain" src="/help/tkgm/tkgm2.png" alt="Bulunamadı" />
                        Harita ekranında farenin sağ tuşuna tıklanarak “Parsel Sorgula” seçeneği seçilerek, seçilen bölgeye ait TKGM parsel bilgisi açılır.
                        <img className="contain" src="/help/tkgm/tkgm3.png" alt="Bulunamadı" />
                    </AccordionTab> */}

                    {/* <AccordionTab header="Raporlar">
                        Uygulamadan sağ üst alanda yer alan “ <img src="/help/rapor/rapor-button.png" alt="Bulunamadı" />  “ sembole tıklanarak Raporlar açılır. Raporlar beş farklı şekilde gösterilmektedir.
                        <img className="contain" src="/help/rapor/rapor1.png" alt="Bulunamadı" />
                        Bölge Raporlamalarında “Bölge Seçiniz” seçeneği ile seçilen bölgeye ait veriler gösterilir.
                        <img className="contain" src="/help/rapor/rapor2.png" alt="Bulunamadı" />
                    </AccordionTab> */}

                    {/* <AccordionTab header="Panorama">
                        Uygulamada panorama iki farklı yöntemle açılabilir.  Birinci yöntem harita Ekranında sağ noktaya panorama ekranında o noktaya en yakın OSB panoramik görüntüsü açılır.
                        <img className="contain" src="/help/panorama/panorama1.png" alt="Bulunamadı" />
                        İkinci yöntem uygulamanın sağ üst alanında yer alan “ <img src="/help/rapor/rapor-button.png" alt="Bulunamadı" />  “  sembole tıklanarak açılır.
                        <img className="contain" src="/help/panorama/panorama2.png" alt="Bulunamadı" />
                    </AccordionTab> */}

                    {/* <AccordionTab header="3B Model">
                        Uygulamadan sağ üst alanda yer alan “ <img src="/help/3b/3b-button.png" alt="Bulunamadı" />  “  sembole tıklanarak 3B Model açılır. 3B Model kapatılması için tekrar sembole tıklanır. Model kapatıldıktan sonra kuzey yönü için uygulamada sağ üstte çıkan “ <img src="/help/3b/3b-arrow-left.png" alt="Bulunamadı" />  “ sembole tıklanır.
                        <img className="contain" src="/help/3b/3b1.png" alt="Bulunamadı" />
                    </AccordionTab> */}

                    <AccordionTab header="Ölçüm">
                        Uygulamadan sağ üst alanda yer alan “ <img src="/help/olcum/olcum_button.png" alt="Bulunamadı" /> ” sembole tıklanarak ölçüm araçları açılır.
                        <img className="contain" src="/help/olcum/resim1.png" alt="Bulunamadı"></img>
                        Harita ekranında; koordinat alma, uzunluk ve alan ölçümleri yapılır. Ölçümleri temizlemek için açılan alanda
                        “Temizle” sembolü seçilerek yapılan çizimler temizlenir.
                        <img className="contain" src="/help/olcum/resim2.png" alt="Bulunamadı"></img>
                        <hr></hr>
                        <b>Ölçüm Video</b>
                        <video className="contain" src="/help/olcum/olcum.mp4" controls></video>
                    </AccordionTab>

                    {/* <AccordionTab header="Dil Seçeneği">
                        Uygulamadan sağ üst köşesinde yer alan “ <img src="/help/language/language-button.png" alt="Bulunamadı" /> ”  sembole tıklanarak uygulamanın dili değiştirilir.
                        <img className="contain" src="/help/language/language1.png" alt="Bulunamadı"></img>
                    </AccordionTab> */}

                    <AccordionTab header="Arama">
                        {/* Uygulamada katman panelinde “KATMAN ARAMA” seçeneği bulunur. Katman Paneli “ “ sembole tıklanarak açılır. Açılan katman panelinde arama alanında harf ve kelime bazlı arama yapılır.
                        <img className="contain" src="/help/arama/arama1.png" alt="Bulunamadı"></img> */}
                        Uygulamanın sol-üst bölümünde bulunan  “ARAMA” penceresinde esnek ve kategorik arama yapılır.
                        <img className="contain" src="/help/arama/arama2.png" alt="Bulunamadı"></img>
                        Esnek aramada yönteminde kelime yazılarak arama sonucu listelenir. Listelenen arama sonuçlarından seçim yapılır ve seçime ait detaylı bilgi penceresi açılır.
                        <img className="contain" src="/help/arama/arama3.png" alt="Bulunamadı"></img>
                        {/* Arama yöntemlerinden kategorik arama için aramada yer alan “ <img src="/help/arama/arama-arrow-down.png" alt="Bulunamadı"></img> ” sembole tıklanır.  Kategorik arama iki şekilde yapılır:
                        <ul>
                            <li>Parsel Arama</li>
                            <li>Firma Arama</li>
                        </ul>
                        <img className="contain" src="/help/arama/arama4.png" alt="Bulunamadı"></img> */}
                        {/* “Parsel Arama” seçeneği seçilerek açılan pencerede istenilen arama seçeneğine göre verilerek girilerek
                        “Ara” butonuna tıklanarak harita ekranı üzerinde arama kriterlerine göre verinin geometrisi getirilir.
                        <img className="contain" src="/help/arama/arama5.png" alt="Bulunamadı"></img>
                        “Firma Arama” seçeneği seçilerek açılan pencerede firma bilgisi girilerek istenilen firmalar listelenir.
                        <img className="contain" src="/help/arama/arama6.png" alt="Bulunamadı"></img> */}
                    </AccordionTab>

                </Accordion>
            </Dialog>
        </>
    )
}
