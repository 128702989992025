import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 'tr'
}
export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setCurrentLanguage: (state: any, action) => {
            state.value = action.payload;
        }
    }
})

export const { setCurrentLanguage } = languageSlice.actions;

export default languageSlice.reducer;