import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    mapCoord: [],
    urlView:null
}

export const urlSlice = createSlice({
    name: 'url',
    initialState,
    reducers: {
        setUrlCoordinate: (state: any, action) => {
            state.mapCoord = action.payload
        },
        setUrlView: (state: any, action) => {
            state.urlView = action.payload
        }
    }
})

export const { setUrlCoordinate, setUrlView } = urlSlice.actions;

export default urlSlice.reducer;