import { createSlice } from '@reduxjs/toolkit';
import { ButtonState } from '../../components/shared/button-state/ButtonState';

const initialState: any = {
    buttonState: ButtonState.NONE,
    sidebarButton: false,
    infoButton: false,
    tableButton: false,
    helpButton: false,
    setImportButton: false,
    setExportButton: false,
    setPrintButton: false,
    screenshotButton: false
}

export const buttonStateSlice = createSlice({
    name: 'buttonState',
    initialState,
    reducers: {
        setButtonState: (state: any, action) => {
            state.buttonState = action.payload;
        },
        setSidebarButton: (state: any, action) => {
            state.sidebarButton = action.payload;
        },
        setTableButton: (state: any, action) => {
            state.tableButton = action.payload;
        },
        setImportButton: (state: any, action) => {
            state.importButton = action.payload;
        },
        setExportButton: (state: any, action) => {
            state.exportButton = action.payload;
        },
        setPrintButton: (state: any, action) => {
            state.printButton = action.payload;
        },
        setScreenshotButton: (state: any, action) => {
            state.screenshotButton = action.payload;
        }
    },
})

export const { setButtonState, setSidebarButton, setTableButton, setImportButton, setPrintButton, setExportButton, setScreenshotButton } = buttonStateSlice.actions

export default buttonStateSlice.reducer