import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  selectedRow: false,
  features: [],
  goToSelection: false,
  tableExtent: [],
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setSelectedRow: (state: any, action) => {
      state.selectedRow = action.payload;
    },
    setFeatures: (state: any, action) => {
      state.features = action.payload;
    },
    setGoToSelection: (state: any, action) => {
      state.goToSelection = action.payload;
    },
    setTableExtent: (state: any, action) => {
      state.tableExtent = action.payload;
    },
  },
});

export const { setSelectedRow, setFeatures, setGoToSelection, setTableExtent } =
  tableSlice.actions;

export default tableSlice.reducer;
