import { useContext, useEffect, useState } from 'react'
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { MapContext } from '../../../map-container/map/MapContext';
import { Mode, TransactWfs } from '../TransactWfs';
import { TransactEdit } from '../TransactEdit';
import Modify from 'ol/interaction/Modify'
import { useDispatch } from 'react-redux';
import Collection from 'ol/Collection';
import MultiPoint from 'ol/geom/MultiPoint';
import Draw from 'ol/interaction/Draw';
import { never } from 'ol/events/condition';
import { useAppSelector } from '../../../../../state/hooks';
import { setCancelDigiActivate, setDigiPopupVisibility, setGeomEditStatus } from '../../../../../state/features/digitizationSlice';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import { setSelectedFeature } from '../../../../../state/features/selectedFeatureSlice';
import { DigiPopup } from '../digi-popup/DigiPopup';

export const DigiGeomEdit = () => {
    const map = useContext(MapContext);
    const dispatch = useDispatch()
    const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
    const [vectorLayer, setVectorLayer] = useState<VectorLayer<any>>(new VectorLayer())
    const [olFeatureForEdit, setolFeatureForEdit] = useState<any>(new Feature())
    const [geojsonFeature, setGeojsonFeature] = useState<any>(undefined)
    const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature)
    const popupSaveStatus: any = useAppSelector(state => state.digitization.popupSaveStatus)
    const geomEditStatus: any = useAppSelector(state => state.digitization.geomEditStatus)
    const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate)
    const panoramaTransactStatus = useAppSelector(state => state.digitization.panoramaTransactStatus)
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility);

    useEffect(() => {
        map.updateSize();
    }, [])

    const style = new Style({
        fill: new Fill({
            color: 'rgb(165, 33, 37, 0.44)',
        }),
        stroke: new Stroke({
            color: '#00FFFF',
            width: 6,
        }),
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({
                color: '#38EEF5',
            }),
        }),
    })

    const styles = [
        new Style({
            fill: new Fill({
                color: 'rgb(165, 33, 37, 0.44)',
            }),
            stroke: new Stroke({
                color: '#00FFFF',
                width: 6,
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#38EEF5',
                }),
            }),
        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'orange',
                }),
            }),
            geometry: (olFeatureForEdit: any) => {
                // return the coordinates of the first ring of the polygon
                const point = new MultiPoint([])
                point.appendPoint(olFeatureForEdit.getGeometry())
                return point;
            },
        }),
    ];

    useEffect(() => {
        if (selectedFeature && selectedFeature.length > 0) {
            if (selectedFeature[0].geometry) {
                const vectorSource = new VectorSource();

                const vector = new VectorLayer({
                    source: vectorSource,
                    zIndex: 10000000000000000,
                    style: style
                });
                map.addLayer(vector);
                setVectorLayer(vector)
                const geoJson = new GeoJSON()
                const feature: any = geoJson.readFeature(selectedFeature[0])
                vectorSource.addFeature(feature);
                setSource(vectorSource)
                if (feature && feature.getGeometry()) {
                    setolFeatureForEdit(feature);
                    setGeojsonFeature(selectedFeature[0]);
                }
                return () => {
                    map.removeLayer(vector)
                    source.clear();
                }
            }
        }
    }, [selectedFeature])

    useEffect(() => {
        if (olFeatureForEdit) {
            let draw = new Draw({
                source: source,
                type: 'Polygon',
                style: new Style({
                    fill: new Fill({
                        color: 'rgb(165, 33, 37, 0.44)',
                    }),
                    stroke: new Stroke({
                        color: '#A52125',
                        width: 2,
                    }),
                    image: new CircleStyle({
                        radius: 5,
                        fill: new Fill({
                            color: '#A52125',
                        }),
                    }),
                }),
                finishCondition: never,
                condition: function (e: any) {
                    //sadece solla çizim yapıyor ve sağla bitiriyor
                    if (e.originalEvent.buttons === 1) {
                        return true;
                    } else if (e.originalEvent.buttons === 2) {
                        draw.finishDrawing();
                        return false;
                    }
                    else {
                        return false;
                    }
                },
            });

            vectorLayer.setStyle(styles)

            const modify = new Modify({
                features: new Collection([olFeatureForEdit])
            })
            map.addInteraction(modify);

            const rightClickHandler = (event: any) => {
                event.preventDefault();
                map.removeInteraction(modify);
                dispatch(setGeomEditStatus(true))
                dispatch(setDigiPopupVisibility(true))
                // bi defa yaptiktan sonra kaldiriyoruz event i
            }
            // sag tiklayinca bitirmesi icin
            document.addEventListener('contextmenu', rightClickHandler);
            (window as any).customRightClick = rightClickHandler; /** ? */
        }

        return ()=>{
            document.removeEventListener('contextmenu', (window as any).customRightClick);
        }

    }, [olFeatureForEdit])

    useEffect(() => {
        if (cancelDigiActivate) {
            dispatch(setSelectedFeature([]))
            dispatch(setCancelDigiActivate(false));
            dispatch(setGeomEditStatus(false))
            dispatch(setButtonState(ButtonState.NONE))
            source.clear();
        }
    }, [cancelDigiActivate])

    return (
        <>
            {digiPopupVisibility && buttonState === ButtonState.VERTEX && <DigiPopup title='Veri Düzenle' content='Kaydetmek istediğinize emin misiniz?' from='Map' mode={Mode.UPDATE} />}
            {!panoramaTransactStatus && geomEditStatus && popupSaveStatus && buttonState === ButtonState.VERTEX && 

            // <TransactWfs
            //  coordinates={olFeatureForEdit.getGeometry().getCoordinates()}
            //  feature={geojsonFeature} mode={Mode.UPDATE}
            //  type={geojsonFeature.geometry.type}
            //  source={source}
            // />

            <TransactEdit
                coordinates={olFeatureForEdit.getGeometry().getCoordinates()}
                feature={olFeatureForEdit} mode={Mode.UPDATE}
                type={geojsonFeature.geometry.type}
                source={source}
            />}

        </>
    )
}
