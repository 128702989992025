import axios from 'axios';

const getToken = () => {
    return localStorage.getItem('token')
}


async function digiAddFeature(payload: any, url: string) {

    return new Promise(async (resolve, reject) => {
        try {
            const addResponse = axios({
                method: 'post',
                url: url + `/ows`, params: { token: getToken() },
                data: payload,
                headers: {
                    'content-type': 'text/plain'
                }
            })
            const parser = new DOMParser();
            let xmlDoc = parser.parseFromString((await addResponse).data, 'text/xml');
            let featureId = xmlDoc.getElementsByTagName('ogc:FeatureId')[0].attributes[0].nodeValue

            resolve(featureId)
        } catch (error) {
            console.warn(error);
            reject()
        }
    })
}

// // verilen geometri ile geometrinin hangi mahalle, il, ilcede oldugunu döndürür.
// async function findProvinceWithGeometry(wktGeom: any) {
//     return axios.get(getGeoserverUrl() + `/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&typeName=mahalle&CQL_FILTER=INTERSECTS(geom, + ${wktGeom} + )`)
// }

// // tüm illeri döndürür
// async function getCities() {
//     return axios.get(getGeoserverUrl() + `/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=iller&outputFormat=application/json&propertyname=adi,il_id`)
// }

// // verilen il_id ile o ildeki ilçeleri döndürür.
// async function getDistincts(cityId: number) {
//     return axios.get(getGeoserverUrl() + `/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ilce&outputFormat=application/json&propertyname=adi,ilce_id&CQL_FILTER=il_id=+${cityId}`)
// }

// // tüm ilçeleri döndürür.
// async function getAllDistincts() {
//     return axios.get(getGeoserverUrl() + `/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ilce&outputFormat=application/json&propertyname=adi,ilce_id`)
// }

// // verilen ilce_id ile o ilcedeki mahalleleri döndürür.
// async function getNeighborhoods(distinctId: number) {
//     return axios.get(getGeoserverUrl() + `/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mahalle&outputFormat=application/json&propertyname=adi,mah_id&CQL_FILTER=ilce_id=+${distinctId}`)
// }

// // tüm mahalleleri döndürür.
// async function getAllNeighborhoods() {
//     return axios.get(getGeoserverUrl() + `/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mahalle&outputFormat=application/json&propertyname=adi,mah_id`)
// }


export const DigitizationRestApi = {
    digiAddFeature
    // digiAddFeature, findProvinceWithGeometry, getCities, getDistincts, getNeighborhoods, getAllDistincts, getAllNeighborhoods
}