import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    legendVisibility: false
}

export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setLegendVisibility: (state: any, action) => {
            state.legendVisibility = action.payload;
        }
    }
})

export const { setLegendVisibility } = mapSlice.actions

export default mapSlice.reducer