import './Footer.scss'
import Moment from 'moment';
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../state/hooks'
import { Dropdown } from 'primereact/dropdown';
import { useContext, useState } from 'react';
import { transform } from 'ol/proj';
import { setPanoramaCoordinate, setPointerCoordinate, setSelectedProjection } from '../../../state/features/panoramaSlice';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Point, SimpleGeometry } from 'ol/geom';
import { MapContext } from '../../containers/map-container/map/MapContext';

export const Footer = () => {
  const formats = [
    { coordinateFormat: 'EPSG:4326' },
    { coordinateFormat: 'EPSG:5253' },
    { coordinateFormat: 'EPSG:5254' },
    { coordinateFormat: 'EPSG:5255' },
    { coordinateFormat: 'EPSG:5256' },
    { coordinateFormat: 'EPSG:5257' },
    { coordinateFormat: 'EPSG:5258' },
    { coordinateFormat: 'EPSG:23035' },
    { coordinateFormat: 'EPSG:23036' },
    { coordinateFormat: 'EPSG:23037' },
    { coordinateFormat: 'EPSG:23038' },
    { coordinateFormat: 'EPSG:32635' },
    { coordinateFormat: 'EPSG:32636' },
    { coordinateFormat: 'EPSG:32637' },
    { coordinateFormat: 'EPSG:32638' },
    { coordinateFormat: 'EPSG:32639' },
    { coordinateFormat: 'EPSG:3857' },
  ];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const map = useContext(MapContext);
  const [visible, setVisible] = useState(false);
  const [coordinates, setCoordinates] = useState<any>();
  const [selectedFormat, setSelectedFormat] = useState<any>(formats[0]);
  const panoramaInfo = useAppSelector(state => state.panorama.info)
  const pointerCoordinate = useAppSelector(state => state.panorama.pointerCoordinate);
  const isFullScreen = useAppSelector(state => state.panorama.isFullScreen);
  const isPanoMiniScreen = useAppSelector(state => state.panorama.isPanoMiniScreen);
  const tableVisibility: any = useAppSelector(state => state.buttonState.tableButton);

  const onSelectedFormatChanged = (e: any) => {
    const value = e.value.coordinateFormat
    const transformed = transform(pointerCoordinate, selectedFormat.coordinateFormat, value);
    const coordinate = value === 'EPSG:4326' ? [+transformed[0].toFixed(6), +transformed[1].toFixed(6)] : [+transformed[0].toFixed(4), +transformed[1].toFixed(4)]
    dispatch(setPointerCoordinate(coordinate))
    setSelectedFormat(e.value);
    dispatch(setSelectedProjection(value))
  }

  const goLocation = () => {
    if (coordinates && coordinates.length > 0) {
      const replacedCoordinateValue = coordinates.replace(/ /g, ',');
      const coordinateStringArray = replacedCoordinateValue.split(',');
      const coordinateNumberArray = coordinateStringArray.map((coordinateString: any) => Number(coordinateString));
      const point = new Point(coordinateNumberArray);
      console.log(coordinateNumberArray);
      dispatch(setPanoramaCoordinate({ lat: coordinateNumberArray[1], lon: coordinateNumberArray[0] }))
      // point.transform('EPSG:4326', 'EPSG:3857');
      // map.getView().fit(point as SimpleGeometry, { duration: 500, maxZoom: 15 })
      setVisible(false)
    }
  }

  const goToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }

  const successCallback = (position: any) => {
    if (position) {
      const coord = position.coords
      dispatch(setPanoramaCoordinate({ lat: coord.latitude, lon: coord.longitude }))
      setVisible(false)
      // setTimeout(() => {
      //   const point = new Point([coord.longitude, coord.latitude]);
      //   point.transform('EPSG:4326', 'EPSG:3857');
      //   map.getView().fit(point as SimpleGeometry, { duration: 500, maxZoom: 19 })
      //   setVisible(false)
      // }, 200);
    }
  };

  const errorCallback = (error: any) => {
    console.log(error);
  };

  return (
    <div className={`footer-content non-printable ${isPanoMiniScreen ? 'footer-content' : ''} ${isFullScreen && !isPanoMiniScreen ? 'footer-content2' : ''} ${tableVisibility && isFullScreen && !isPanoMiniScreen ? 'footer-content3' : ''} ${!tableVisibility && isFullScreen && !isPanoMiniScreen ? 'footer-content4' : ''}`}>
      {<div className="left" >
        {/* <div className='label text'>
          {panoramaInfo.label}
        </div>
        <div className='label text' >
          {Moment(new Date(panoramaInfo.shootingDate)).format('DD-MM-YYYY hh:mm')}
        </div> */}
      </div>}
      <div className="right">
        <div className='coordinate'>
          <span className='text'>{t('FOOTER.Coordinate')}:&nbsp;</span>
          <span className='text'> {pointerCoordinate[0]}, {pointerCoordinate[1]}</span>
        </div>
        <Dropdown value={selectedFormat} options={formats} onChange={onSelectedFormatChanged} optionLabel="coordinateFormat" />
        {/* <Button className={visible ? 'go-to-location-active-button' : 'go-to-location-button'} onClick={() => setVisible(true)}>
          <img src="icons/go-to-location.svg" alt="" width="20px" height='20px' />
        </Button> */}
        <div style={{width:'8px'}}> </div>
        <Dialog header={t('FOOTER.Go To Location')} visible={visible} onHide={() => setVisible(false)}>
          <div style={{ padding: '1rem 0' }}>
            <InputText placeholder="26.43,43.26" onInput={(e: any) => setCoordinates(e.target.value)} />
            <Button label={t('FOOTER.Go Location')} onClick={goLocation} style={{ width: '33px', height: '33px', color: 'white' }} />
            <Button label={t('FOOTER.Go Current Location')} onClick={goToCurrentLocation} style={{ width: '100px', height: '33px', color: 'white' }} />
          </div>
        </Dialog>
      </div>
    </div>
  )
}
