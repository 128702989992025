import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    digiPopupVisibility: false,
    cancelDigiActivate: false,
    transactStatus: false,
    panoramaTransactStatus: false,
    multipleDigiStatus: false,
    changedFields: [],
    editedProperties: {},
    refreshData: false,
    confirmPopupVisibility: false,
    popupSaveStatus: false,
    geomEditStatus: false,
    panoramaEditStatus: false,
    fields: [],
    screenshotFileNames: [],
    featureAttachments: {
        attachments: [],
        fileNames: [],
    }
}

export const digitizationSlice = createSlice({
    name: 'digitization',
    initialState,
    reducers: {
        setDigiPopupVisibility: (state: any, action) => {
            state.digiPopupVisibility = action.payload;
        },
        setCancelDigiActivate: (state: any, action) => {
            state.cancelDigiActivate = action.payload;
        },
        setTransactStatus: (state: any, action) => {
            state.transactStatus = action.payload;
        },
        setPanoramaTransactStatus: (state: any, action) => {
            state.panoramaTransactStatus = action.payload;
        },
        setChangedFields: (state: any, action) => {
            state.changedFields = action.payload;
        },
        setEditedProperties: (state: any, action) => {
            state.editedProperties = action.payload;
        },
        setConfirmPopupVisibility: (state: any, action) => {
            state.confirmPopupVisibility = action.payload;
        },
        setPopupSaveStatus: (state: any, action) => {
            state.popupSaveStatus = action.payload;
        },
        setGeomEditStatus: (state: any, action) => {
            state.geomEditStatus = action.payload
        },
        //map ile panodaki ortak işlemleri ayırmak için
        setPanoramaEditStatus: (state: any, action) => {
            state.panoramaEditStatus = action.payload
        },
        //çoklu edit ve delete işlemlerinden sonra tablo yenilemesi için
        setMultipleDigiStatus: (state: any, action) => {
            state.multipleDigiStatus = action.payload
        },
        //feature eklerken ya da düzenlerken aynı anda ekran görüntüsü alırken field kontrolü yapmak için
        setSelectedLayerFields: (state: any, action) => {
            state.fields = action.payload
        },
        //feature eklerken ya da düzenlerken alınan ekran görüntüsünü setlemek için
        setScreenshotFileNames: (state: any, action) => {
            state.screenshotFileNames = action.payload
        },
        setFeatureAttachments: (state: any, action) => {
            state.featureAttachments = action.payload
        },
    },
})

export const { setDigiPopupVisibility, setCancelDigiActivate, setTransactStatus, setChangedFields, setEditedProperties, setConfirmPopupVisibility, setPanoramaEditStatus, setPanoramaTransactStatus, setMultipleDigiStatus, setPopupSaveStatus, setGeomEditStatus, setSelectedLayerFields, setScreenshotFileNames, setFeatureAttachments } = digitizationSlice.actions

export default digitizationSlice.reducer