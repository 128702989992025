import './Legend.scss'
import { useEffect, useState } from 'react'
import { LegendRestApi } from '../../util/restapi/legend'

export const Legend = () => {
    const [legend, setLegend] = useState('')

    useEffect(() => {
        LegendRestApi.getLegends().then((res) => {
            var blob = new Blob([res.data])

            var reader = new FileReader();
            reader.onload = function (event: any) {
                var base64 = event.target.result
                setLegend(base64);
            };
            reader.readAsDataURL(blob);
        })
    }, [])
    return (
        <>
            {legend && <img  className='image-style' src={'data:image/png;base64' + legend} alt="legend" />}
        </>
    )
}
