import { useContext, useEffect, useState } from 'react'
import Draw from 'ol/interaction/Draw';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { transform } from 'ol/proj';
import Text from 'ol/style/Text';
import { MapContext } from '../../../map-container/map/MapContext';
import { useDispatch } from 'react-redux';
import Snap from 'ol/interaction/Snap';
import { useAppSelector } from '../../../../../state/hooks';
import { DigiPopup } from '../digi-popup/DigiPopup';
import { setCancelDigiActivate, setDigiPopupVisibility } from '../../../../../state/features/digitizationSlice';
import VectorSource from 'ol/source/Vector';
import { DrawType, Mode, TransactWfs } from '../TransactWfs';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import Feature from 'ol/Feature';

interface Props {
    source: VectorSource;
}
/* 
 * Bu componentle point geometri tipinde bir feature oluşrutuyoruz.
 *
*/
export const DigiDrawPoint = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();

    const [feature, setFeature] = useState<any>(undefined)
    const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility)
    const transactStatus = useAppSelector(state => state.digitization.transactStatus)
    const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate)
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    
    useEffect(() => {
        const vectorSource = props.source;
        const featureForParallel : any = vectorSource.getFeatures()[0]
        if(featureForParallel){ 
            const feature = new Feature()
            feature.setGeometry(featureForParallel.values_.geometry)
            setFeature(feature)
            dispatch(setDigiPopupVisibility(true))
        }
    }, [])
    
    useEffect(() => {
        
        let draw = new Draw({
            source: props.source,
            type: 'Point',
            style: new Style({
                fill: new Fill({
                    color: 'red',
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: 'red',
                    }),
                }),

            }),
        });
        const snap = new Snap({
            source: props.source
        });

        map.addInteraction(snap);
        map.addInteraction(draw);

        draw.on('drawend', (event) => {
            map.removeInteraction(draw)
            setTextAfterDrawEnd(event)
            setFeature(event.feature)
        });
        const content = map.getTargetElement();
        content.addEventListener('contextmenu', (event: MouseEvent) => {
            map.removeInteraction(draw);
        });

        return () => {
            map.removeInteraction(draw)
            props.source.clear();
        }
    }, [map])

    /**
      * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
      */
    const setTextAfterDrawEnd = (event: any) => {
        dispatch(setDigiPopupVisibility(true))

        let coordinates: any = event.feature?.getGeometry().getCoordinates();
        coordinates = transform(coordinates, 'EPSG:3857', 'EPSG:4326');

        coordinates[0] = coordinates[0].toFixed(6)
        coordinates[1] = coordinates[1].toFixed(6)
        event.feature.setStyle(new Style({
            fill: new Fill({
                color: 'red',
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'red',
                }),
            }),
            text: new Text({
                text: `${coordinates}`,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
            }),
        }));
    }

    useEffect(() => {
        if (cancelDigiActivate) {
            props.source.clear();
            map.updateSize();
            dispatch(setCancelDigiActivate(false));
            dispatch(setButtonState(ButtonState.NONE))
        }
    }, [cancelDigiActivate])

    return (
        <>
            {digiPopupVisibility && <DigiPopup mode={Mode.INSERT} title='Add New Feature' from='Map' />}
            {transactStatus && (buttonState === ButtonState.ADD || buttonState === ButtonState.PARALLEL) && <TransactWfs coordinates={feature.getGeometry().getCoordinates()} feature={feature} mode={Mode.INSERT} source={props.source} type={DrawType.POINT} />}
        </>
    )
}
