import './CheckBox.scss';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { useTranslation } from 'react-i18next';


const CheckBox = ({ label, changeChecked }: any) => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState<boolean>(false);
    const printPreviewMode: boolean = useAppSelector(state => state.print.printPreviewMode);
    const { t } = useTranslation();

    // useEffect(() => {
    //     console.log("changeChecked");
        
    //     changeChecked(checked);
    // }, [checked])
    const changeIt = ()=>{
        setChecked(!checked); changeChecked(!checked); 
    }
    useEffect(() => {
        if(printPreviewMode && label === t(`BUTTON.Preview`)){
            setChecked(printPreviewMode); 
        }
    }, [printPreviewMode]);
    return (
            <div className="checkbox">
                <label onClick={changeIt} className="checkbox-text">{label ? label : ''}
   
                    <svg className={`checkbox-input ${checked ? 'active' : ''} `}
                        xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox={"0 0 30 30"} enableBackground={"new 0 0 30 30"}
                        // xml:space="preserve"
                        >
                        <g>
                        <path className="none_" fill="none" stroke="#5C6273" strokeWidth={"2"} strokeMiterlimit={"10"} d="M24,21c0,1.65-1.35,3-3,3H9c-1.65,0-3-1.35-3-3V9
                        c0-1.65,1.35-3,3-3h12c1.65,0,3,1.35,3,3V21z" />
                        </g>
                        <path className="none" fill="#5C6273" d="M23,14.846V21c0,1.104-0.896,2-2,2H9c-1.103,0-2-0.896-2-2V9c0-1.103,0.897-2,2-2h9.994l1.777-2H9
                                        C6.794,5,5,6.794,5,9v12c0,2.205,1.794,4,4,4h12c2.205,0,4-1.795,4-4v-8.384L23,14.846z" />
                        <path className="none" fill="#5C6273" d="M24.646,8.747c0.164,0.166,0.256,0.4,0.258,0.646c0.025,0.251-0.074,0.499-0.258,0.646l-7.73,8.62
                                        l-1.095,1.221c-0.149,0.182-0.359,0.287-0.58,0.287c-0.224,0.029-0.446-0.082-0.578-0.287l-1.096-1.219l-4.252-4.813
                                        c-0.182-0.147-0.278-0.393-0.254-0.642c-0.025-0.251,0.072-0.498,0.257-0.646l1.028-1.226c0.149-0.182,0.359-0.286,0.58-0.287
                                        c0.225-0.028,0.446,0.082,0.579,0.287l3.671,4.094l7.085-7.973c0.166-0.196,0.4-0.301,0.643-0.287
                                        c0.227-0.028,0.447,0.082,0.58,0.287L24.646,8.747z" />
                    </svg>
                </label>
            </div>
    )
}

export default CheckBox