import { createSlice } from '@reduxjs/toolkit';

interface IFeatureState {
    feature: [],
    extent: any,
    ctrlDown: boolean
}

const initialState: IFeatureState = {
    feature: [],
    extent: false,
    ctrlDown: false
}

export const selectedFeatureSlice = createSlice({
    name: 'selectedFeature',
    initialState,
    reducers: {
        setSelectedFeature: (state: any, action) => {
            state.feature = action.payload;
        },
        setFeatureExtent: (state: any, action) => {
            state.extent = action.payload;
        },
        setCtrlDown: (state: any, action) => {
            state.ctrlDown = action.payload;
        }
    }
})

export const { setSelectedFeature, setFeatureExtent, setCtrlDown } = selectedFeatureSlice.actions

export default selectedFeatureSlice.reducer