
import { MapBrowserEvent } from 'ol';
import { Tile } from 'ol/layer';
import { TileWMS } from 'ol/source';
import { useContext, useEffect } from 'react'
import { useAppSelector } from '../../../../../state/hooks';
import { MapContext } from '../MapContext';
import { Project } from '../../../../../util/model/project';
import { RootState } from '../../../../../state/store';

interface Props {
    tag: string;
    callback: (arr: { url: string, wms: Tile<TileWMS> }[], coordinate: number[]) => void
}
export default function WmsInfo(props: Props) {
    const map = useContext(MapContext)
    const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects);

    const onClick = (event: MapBrowserEvent<any>) => {
        const view = map.getView();
        const layers = map.getLayers().getArray()
            .filter((layer: any) => layer.get('tag') === props.tag)
            .filter((layer2: any) => layer2.getVisible())
            .map((layer: any) => {
                const wms: any = layer;
                const url = wms.getSource().getFeatureInfoUrl(
                    event.coordinate,
                    view.getResolution()!,
                    view.getProjection(),
                    {
                        QUERY_LAYERS: wms.getSource().getParams().layers,
                        info_format: 'application/json',
                        feature_count: 10,
                    }
                )!;
                return { url, wms }
            })
        setTimeout(() => {
            props.callback(layers, event.pixel);
        }, 500);
    }

    useEffect(() => {
        map.on('click', onClick)

        return () => {
            map.un('click', onClick)
        }
    }, [props.callback, map, projects]);

    return null;
}