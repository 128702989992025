import './DigiPopup.scss';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCancelDigiActivate, setChangedFields, setDigiPopupVisibility, setEditedProperties, setFeatureAttachments, setPanoramaTransactStatus, setPopupSaveStatus, setSelectedLayerFields, setTransactStatus } from '../../../../../state/features/digitizationSlice';
import { useAppSelector } from '../../../../../state/hooks';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { AttachmentRestApi } from '../../../../../util/restapi/attachment';
import { DigiCallbackContext } from '../DigiCallbackContextProvider';
import { useTranslation } from 'react-i18next';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import React from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { ColorPicker } from 'primereact/colorpicker';
interface Props {
    title: string;
    from: string;
    content?: string;
    mode: any
}
addLocale('tr', { closeText: 'kapat', prevText: 'geri', nextText: 'ileri', currentText: 'bugün', monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'], monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'], dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'], dayNamesShort: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'], dayNamesMin: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'], weekHeader: 'Hf', firstDay: 1, isRTL: false, showMonthAfterYear: false, yearSuffix: '', timeOnlyTitle: 'Zaman Seçiniz', timeText: 'Zaman', hourText: 'Saat', minuteText: 'Dakika', secondText: 'Saniye', ampm: false, month: 'Ay', week: 'Hafta', day: 'Gün', allDayText: 'Tüm Gün' });
export const DigiPopup = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const digiCallback = useContext(DigiCallbackContext)
    const [fields, setFields] = useState<any>([])
    const [attachment, setAttachment] = useState<File[]>([]);
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer);
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature);
    const featureAttachments: any = useAppSelector(state => state.digitization.featureAttachments);
    const cal = useRef(null);


    useEffect(() => {
        if (selectedLayer) {
            const filteredFields = selectedLayer.fields.filter((field: any) => field.type !== 'bbox' && field.type !== 'geom' && field.type !== 'Id' && field.type !== 'CreatedBy' && field.type !== 'CreatedDate' && field.type !== 'TouchBy' && field.type !== 'TouchDate')
            const sortedFields = filteredFields.sort((a: any, b: any) => a.priority - b.priority);
            setFields(sortedFields);
            dispatch(setSelectedLayerFields(sortedFields))
        }
    }, [selectedLayer])

    useEffect(() => {
        if (featureAttachments.attachments && featureAttachments.attachments.length > 0) {
            for (const attach of featureAttachments.attachments) {

                var reader = new FileReader();
                reader.onload = function () {
                    var dataURL = reader.result;
                    var output = document.getElementById('output');
                };
                reader.readAsDataURL(attach);
                // console.log(reader);
            }


            // setAttachment(attachment ? [...attachment, screenshotPicture[0]] : screenshotPicture)
            // dispatch(setFeatureAttachments(attachment))
        }
    }, [featureAttachments])


    const onHide = () => {
        dispatch(setDigiPopupVisibility(false))
        dispatch(setCancelDigiActivate(true))
    }

    const onChangeInput = (fieldId: string, fieldName: string, fieldValue: any, event: any, fieldType?: any) => {
        if (props.title === 'Add New Feature') {
            const copyFields = [...fields]
            const findRowIndex: any = fields.findIndex((field: any) => field.id === fieldId);
            copyFields[findRowIndex] = { ...copyFields[findRowIndex], inputValue: fieldValue };
            setFields(copyFields)
        }
    }

    const onFileSelect = (e: any, field: any) => {
        let _totalSize = field.fileSize ? field.fileSize : 0;
        const files = [...e.files]

        files.forEach((file: any) => {
            _totalSize += file.size;
        });

        setAttachment([...attachment, files[0]])

        let findedAttFieldIndex = fields.findIndex((attField: any) => attField.id === field.id);
        const copyFields = [...fields]
        copyFields[findedAttFieldIndex] = {
            ...copyFields[findedAttFieldIndex],
            files: attachment,
            // fileSize: _totalSize
        }
        setFields(copyFields)
    }

    const headerTemplate = (options: any) => {
        options.props.chooseOptions.label = `${t('BUTTON.Select File')}`
        options.props.chooseOptions.icon = 'none'
        const { chooseButton } = options;

        return (
            <>
                {chooseButton}
            </>
        );
    }


    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <span style={{ padding: '10px', 'fontSize': '1em', color: 'var(--text-color-secondary)' }} className="my-5">{t('BUTTON.Select File')}</span>
            </div>
        )
    }

    const itemTemplate = (file: any, props: any) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="item-group">
                    {file.objectURL ?
                        <img alt={file.name} role="presentation" src={file.objectURL} width={70} /> :
                        <div className='image-box'>
                            <i className="pi pi-file" style={{ 'fontSize': '2em', color: '#863564' }}></i>
                        </div>
                    }
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                    </span>
                    <Tag value={props.formatSize} className="px-3 py-2" />
                    <Button type="button" icon="pi pi-times" className="p-button-sm p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(props.onRemove)} />
                </div>
            </div>
        )
    }

    const onTemplateRemove = (callback: any) => {
        callback();
    }

    const onSubmit = (event: any) => {
        event.preventDefault();

        dispatch(setChangedFields(fields))
        if (props.from === 'Map') {
            dispatch(setTransactStatus(true))
            dispatch(setPanoramaTransactStatus(false))
            dispatch(setPopupSaveStatus(true));

        }
        if (props.from === 'Panorama') {
            dispatch(setPanoramaTransactStatus(true))
            dispatch(setTransactStatus(false))
            dispatch(setPopupSaveStatus(true));
        }
        onHide();
        //attachment için
        digiCallback.setCallbackFunction((params) => {
            return new Promise(async resolve => {
                if (attachment && attachment.length > 0) {
                    for (const file of attachment) {
                        const f = new FormData()
                        f.append('file', file)
                        AttachmentRestApi.addAttachment(selectedLayer.id, params, f).then((res: any) => {
                        }).catch((err: any) => { console.error(err); })
                        resolve(true)
                    }
                }
            })
        })
    }

    const deleteFile = (test: any) => { }

    return (
        <><Dialog className='digi-popup' header={`${selectedLayer.alias}`} visible={true} onHide={onHide}
            draggable={true} resizable={true} position='center'>
            {(buttonState === ButtonState.VERTEX || buttonState === ButtonState.MOVE || buttonState === ButtonState.HOLE) && <><p>{props.content}</p><div className='buttons'>
                <Button label='İptal' onClick={onHide}></Button>
                <Button label='Kaydet' className='save-button' onClick={onSubmit}></Button>
            </div></>}
            {/*  */}
            {props.mode === 'INSERT' && <form onSubmit={onSubmit} className='form'>
                {fields.map((field: any, index: any) => <React.Fragment key={field.id}>
                    {(field.type === 'Text' || field.type === 'Telephone') &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText name={field.name} key={field.id} id={field.alias} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}
                    {field.type === 'CreatedBy' &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}
                    {field.type === 'CreatedDate' &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}
                    {field.type === 'Id' &&
                        <>
                            {!field.nullable ? <label htmlFor='field'>{field.alias} *</label> : <label htmlFor='field'>{field.alias}</label>}
                            <InputText disabled name={field.name} key={field.id} id={field.alias} value={field.inputValue} onChange={(e) => onChangeInput(field.id, field.name, e.target.value, e)} required={!field.nullable} maxLength={250} className='input-style' />
                        </>}
                    {field && (field.type === 'Float') &&
                        <>
                            {!field.nullable ? <label htmlFor='minmaxfraction'>{field.alias} *</label> : <label htmlFor='minmaxfraction'>{field.alias}</label>}
                            <InputNumber locale="en-US" minFractionDigits={1} maxFractionDigits={6} name={field.name} inputId='minmaxfraction' id={field.alias} value={field.inputValue} required={!field.nullable} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='input-style' />
                        </>}
                    {field && (field.type === 'Integer') &&
                        <>
                            {!field.nullable ? <label htmlFor='int'>{field.alias} *</label> : <label htmlFor='int'>{field.alias}</label>}
                            <InputNumber name={field.name} inputId='int' mode='decimal' id={field.alias} value={field.inputValue} required={!field.nullable} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='input-style' />
                        </>}
                    {field && field.type === 'Domain' &&
                        //burası
                        <>
                            {!field.nullable ? <label htmlFor='domain'>{field.alias} *</label> : <label htmlFor='domain'>{field.alias}</label>}
                            <Dropdown name={field.name} className='input-style' value={field.inputValue} required={!field.nullable ? !field.inputValue : false} options={field.domain} optionLabel='desc' optionValue='id' onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e, field.type)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                        </>}

                    {field && (field.type === 'Color') &&
                        <>
                            {!field.nullable ? <label htmlFor='color'>{field.alias} *</label> : <label htmlFor='color'>{field.alias}</label>}
                            <ColorPicker format="hex" name={field.name} inputId='color' id={field.alias} value={field.inputValue} required={!field.nullable} onChange={(e: any) => onChangeInput(field.id, field.name, e.value, e)} className='input-style' />
                        </>}

                    {field && (field.type === 'Date') &&
                        <>
                            {!field.nullable ? <label htmlFor='date'>{field.alias} *</label> : <label htmlFor='date'>{field.alias}</label>}

                            <Calendar
                                locale="tr"
                                className={`.p-calendar .p-inputtext input-style`}
                                name={field.name}
                                value={field.inputValue}
                                onChange={(e: any) => {
                                    onChangeInput(field.id, field.name,
                                        new Date(e.value).getFullYear() + '-' +
                                        ('0' + (new Date(e.value).getMonth() + 1)).slice(-2) + '-' +
                                        ('0' + new Date(e.value).getDate()).slice(-2), e)
                                }}
                                dateFormat="dd-mm-yy"
                                readOnlyInput
                                placeholder='Başlangıç Tarihi Seçiniz'
                                ref={cal}
                                id={field.alias}
                                required={!field.nullable}
                            >
                            </Calendar>
                        </>}
                    {field && field.type === 'Attachment' &&
                        <>
                            {!field.nullable ? <label htmlFor='domain'>{field.alias} *</label> : <label htmlFor='domain'>{field.alias}</label>}
                            <FileUpload name="demo" headerTemplate={headerTemplate} emptyTemplate={emptyTemplate} itemTemplate={itemTemplate} accept="/*" maxFileSize={5000000} onSelect={(e) => onFileSelect(e, field)} className='input-style' />
                            {featureAttachments && featureAttachments.attachments.length > 0 && featureAttachments.attachments.map((attach: any, i: any) =>
                                <div className='attach'>
                                    <>
                                        <img className='digi-screenshot-img' src={attach ? attach : 'customize-icons/file.svg'} alt='' style={{ maxWidth: '900px' }} />
                                        <div className='button-container'>
                                            <a className='anim anim-from-left' onClick={() => deleteFile(attach)}>
                                                Sil
                                            </a>
                                        </div>
                                    </>
                                </div>
                            )
                            }
                        </>}

                </React.Fragment>
                )}
                <div className='buttons'>
                    <Button type={'button'} label='İptal' onClick={onHide}></Button>
                    <Button label='Kaydet' type="submit" className='save-button'></Button>
                </div>
            </form>}
        </Dialog></>

    )
}

